<script lang="ts" setup>
import {
  computed,
  defineProps, type PropType
} from 'vue';
import type {EpisodeOfCareInfo} from 'src/types/webContracts';
import {useSessionStore} from 'src/store';
import {useQuasar} from 'quasar';
import {
  useRoute,
  useRouter
} from 'vue-router';
import {setSelectedProcedure} from 'src/api/endpoints/userWeb';
import axios from 'axios';
import {i18n} from '@/plugins/i18n';
import Svg from '@/components/Svg.vue';

const props = defineProps({
  disable: {
    type: Boolean,
    default: false
  },
  procedure: {
    type: Object as PropType<EpisodeOfCareInfo>,
    required: true
  },
  showDisabledProcs: {
    type: Boolean,
    default: true
  }
});

const sessionStore = useSessionStore();

const $q = useQuasar(),
  $route = useRoute(),
  $router = useRouter();

const selectedEoc = computed(() => {
  return sessionStore.user?.selectedEocId === props.procedure?.eocId
      && $route.name !== 'SelectCarePlan';
});

const onProcedureClicked = async (id: number) => {
  try {
    const selectedProcedure = await setSelectedProcedure(id);

    if (selectedProcedure) {
      sessionStore.setSelectedEocId(id);

      if ($route.name === 'Dashboard.Home') {
        $router.push({ name: 'Interrupts', params: { reload: 'true' } });
      } else {
        $router.push({ name: 'Interrupts' });
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      $q.notify({ type: 'error', message: i18n.global.t(error.response?.data?.errorMessageKey).toString() })
    }
  }
};
</script>

<template>
  <zbm-btn v-show="procedure.status !== 3 || showDisabledProcs"
           @click="onProcedureClicked(procedure.eocId)"
           :class="[{ 'discharged': procedure.status === 3 }]"
           :disable="props.disable"
           :outline="false"
           :rounded="false"
           :square="true">

    <!-- Icon & # of Notifications -->
    <div class="col-auto">
      <q-avatar class="no-border"
                size="50px">

        <!-- Icon -->
        <Svg :view-box="procedure?.bodyPartIcon?.viewBox"
             :vector-paths="procedure?.bodyPartIcon?.vectorPaths" />

        <!-- # of Notifications -->
        <div v-if="procedure.totalAlerts > 0"
             class="notifications">
          {{ procedure.totalAlerts }}
        </div>
      </q-avatar>
    </div>

    <!-- Content -->
    <div class="col text-left content">
      {{ procedure.lateralityBodyPartLocalized }}
      <template v-if="procedure.status === 3 || procedure.totalAlerts > 0">
        <br />
        <span class="sub-text">
            <template v-if="procedure.status === 3">{{ $t('eoc_discharged') }}</template>
            <template v-else-if="procedure.totalAlerts > 0">
              <template v-if="procedure.alerts.tasks > 0">
                <span v-if="procedure.alerts.tasks === 1">{{ $t('vue3_task_count_pluralization_one', [procedure.alerts.tasks]) }}</span>
                <span v-else>{{ $t('vue3_task_count_pluralization_many', [procedure.alerts.tasks]) }}</span>
              </template>
              <template v-if="procedure.alerts.messages > 0">
                <span v-if="procedure.alerts.messages === 1">{{ $t('vue3_message_count_pluralization_one', [procedure.alerts.messages]) }}</span>
                <span v-else>{{ $t('vue3_message_count_pluralization_many', [procedure.alerts.messages]) }}</span>
              </template>
              <template v-if="procedure.alerts.videoVisit > 0">
                <span v-if="procedure.alerts.videoVisit === 1">{{ $t('vue3_video_visit_count_pluralization_one', [procedure.alerts.videoVisit]) }}</span>
                <span v-else>{{ $t('vue3_video_visit_count_pluralization_many', [procedure.alerts.videoVisit]) }}</span>
              </template>
            </template>
          </span>
      </template>
    </div>

    <!-- Angle Right -->
    <div class="col-auto">
      <q-icon :color="selectedEoc ? 'primary' : undefined"
              :name="selectedEoc ? 'fa-solid fa-circle-check' : 'fa-regular fa-angle-right'"
              :size="selectedEoc ? '20px' : '28px'" />
    </div>
  </zbm-btn>
</template>

<style lang="scss" scoped>
.q-btn {
  $q-btn-brdr: 2px solid map-get($co-brand-clrs, content-grd-bg-content);

  background-color: map-get($co-brand-clrs, cnt-branded-grd-2);
  font-weight: map-get($text-weights, regular);
  color: map-get($co-brand-clrs, content-grd-bg-content);
  padding: 20px 15px;
  border-block-end: $q-btn-brdr;

  &:first-child {
    border-block-start: $q-btn-brdr;
  }

  .q-avatar {
    background-color: $bkgd-accent-clr;
    color: map-get($co-brand-clrs, content-assets-hero-def);

    // Icon
    svg {
      width: 46px;
      height: 46px;
    }

    // # of Notifications
    .notifications { // <div>
      position: absolute;
      top: -10px;
      right: -2px;
      width: 26px;
      height: 26px;
      background-color: $accent;
      font-weight: map-get($text-weights, semibold);
      font-size: map-get($font-sizes, '14');
      line-height: 22px;
      color: $white;
      text-align: center;
      border: 2px solid map-get($co-brand-clrs, content-grd-bg-content);
      border-radius: 50%;
    }
  }

  // Content
  .content { // [class*="col"]
    font-size: map-get($font-sizes, '20');
    padding-inline: 12px;

    // Sub-Text
    .sub-text { // <span>
      font-size: map-get($font-sizes, '16');
      opacity: 0.7;

      & > span:not(:last-child)::after {
        content: ", ";
      }
    }
  }

  // Discharged
  &.discharged {

    .q-avatar {
      color: map-get($co-brand-clrs, content-assets-badge-inactive);

      // # of Notifications
      .notifications { // <div>
        display: none;
      }
    }

    // Content -> Sub-Text
    .content .sub-text { // <span>
      font-style: italic;
    }
  }

  // EOC Modal
  .q-dialog & {
    background-color: initial;
    color: map-get($co-brand-clrs, content-txt-ttl);
    padding: 15px 0;
    border-block-width: 1px;
    border-block-color: $border-clr;

    .q-avatar {
      background-color: map-get($co-brand-clrs, content-assets-hero-def);
      color: $white;

      // # of Notifications
      .notifications { // <div>
        top: -8px;
        left: 0;
        width: 22px;
        height: 22px;
        border: none;
      }
    }

    // Content
    .content { // [class*="col"]
      font-size: map-get($font-sizes, '18');

      // Sub-Text
      .sub-text { // <span>
        font-size: map-get($font-sizes, '14');
        color: map-get($co-brand-clrs, content-txt-subtitle);
      }
    }
  }
}
</style>
