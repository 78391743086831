<script lang="ts" setup>
import {
  computed,
  defineProps,
  type PropType
} from 'vue';
import {
  AIProgressDataTypes,
  AIProgressRanks,
  RcvyProgDataCollStatus
} from '@/constants/enums';
import { i18n } from '@/plugins/i18n';
import Svg from '@/components/Svg.vue';
import {
  comparison,
  hkWalkSessions,
  piqQualifiedSteps,
  piqWalkMotion
} from '@/assets';
import type { Metric } from '@/types/webContracts';


const props = defineProps({
  dataStatus: {
    type: String as PropType<RcvyProgDataCollStatus>,
  },
  dataString: {
    type: String,
  },
  metrics: {
    type: Array as PropType<Metric[]>,
    default: () => []
  }
});

const $i18nt = i18n.global.t;
const interpolateDataStr = computed(() => {
  let str = props.dataString;

  if (props.dataStatus === RcvyProgDataCollStatus.Pre) {
    str = `&#x2728 ${str}`;
  } else if (props.dataStatus === RcvyProgDataCollStatus.Post) {
    str = `&#x1F3C5 ${str}`;
  }

  return str;
});

const dataStrSubtext = computed(() => {
  let str: string | undefined = undefined;

  if (props.dataStatus === RcvyProgDataCollStatus.Pre) {
    str = $i18nt('banner_comingup_appointment_viewdetails');
  } else if (props.dataStatus === RcvyProgDataCollStatus.Post) {
    str = $i18nt('patient_progress_rank_title_past');
  }

  return str;
});

function determineRank(value: AIProgressRanks | null): string {
  let rank = '';

  switch (value) {
    case AIProgressRanks.Low:
      rank = 'bg-secondary';
      break;
    case AIProgressRanks.OnTrack:
      rank = 'bg-primary';
      break;
    case AIProgressRanks.High:
      rank = 'bg-negative';
      break;
    default:
      break;
  }

  return rank;
}
</script>

<template>
  <div class="rcvy-prog">
    <div class="row flex-center mb-24">

      <!-- Title -->
      <div class="col pt-8 title">

        <!-- Graphics -->
        <div class="row items-end no-wrap mb-8">

          <!-- Comparison Icon -->
          <div class="col-auto">
            <Svg :vector-paths="comparison.vectorPaths" :view-box="comparison.viewBox"></Svg>
          </div>

          <!-- Low -->
          <div class="col-auto bg-secondary low" />

          <!-- On-Track -->
          <div class="col-auto bg-primary on-track" />

          <!-- High -->
          <div class="col-auto bg-negative high" />
        </div>

        <!-- Text -->
        <p class="">{{ $t('progress_like_you') }}</p>
      </div>
      <div class="flex-br mb-10" />

      <!-- Icons -->
      <div class="col-auto pl-24 icons">
        <div class="row no-wrap">
          <div v-for="(item, i) in props.metrics" class="col-auto" :key="i">
            <div :class="['circle', determineRank(item.rank as AIProgressRanks)]">

              <!-- HK Walking Sessions -->
              <Svg v-if="item.comparisonDataType == AIProgressDataTypes.WalkSessionCountAvg"
                :vector-paths="hkWalkSessions.vectorPaths" :view-box="hkWalkSessions.viewBox" />

              <!-- HK Steps -->
              <q-icon v-else-if="item.comparisonDataType == AIProgressDataTypes.StepsAvg" name="fa-solid fa-shoe-prints" />

              <!-- PIQ Walking Motion -->
              <Svg v-else-if="item.comparisonDataType == AIProgressDataTypes.KneeROMAvg"
                :vector-paths="piqWalkMotion.vectorPaths" :view-box="piqWalkMotion.viewBox" />

              <!-- PIQ Qualified Steps -->
              <Svg v-else-if="item.comparisonDataType == AIProgressDataTypes.MaxStepsAvg"
                :vector-paths="piqQualifiedSteps.vectorPaths" :view-box="piqQualifiedSteps.viewBox" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-link :to="{ name: 'Dashboard.MyStats.MyProgress' }">

      <!-- Data String (1st Row, 1st Column, 1st Child) -->
      <div v-html="interpolateDataStr" />

      <!-- Angle Right Icon (1st Row, 2nd Column, 2nd Child) -->
      <div>
        <q-icon name="fa-solid fa-chevron-right" />
      </div>

      <!-- Data String Subtext (1st Column, 3rd Child) -->
      <div v-if="dataStrSubtext">{{ dataStrSubtext }}</div>

      <!-- Grid Placeholder -->
      <div></div>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.rcvy-prog {
  $rcvy-prog-inline-pad: 20px;
  $rcvy-prog-brdr-width: 1px;
  $rcvy-prog-brdr-radius: 12px;

  width: 100%;
  background-color: $white;
  overflow: visible;
  padding-block-start: 16px;
  padding-inline: $rcvy-prog-inline-pad;
  border: $rcvy-prog-brdr-width solid $todays-tasks-brdr-clr;
  border-radius: $generic-border-radius;

  .title {
    // [class*="col"]

    .row {

      >[class*="col"] {
        line-height: 0;

        // Comparison Icon
        &:first-child {
          // [class*="col"]
          color: map-get($co-brand-clrs, content-assets-hero-def);
          margin-inline-end: 4px;

          svg {
            width: auto;
            height: 24px;
          }
        }

        // Low, On-Track & High
        &:not(:first-child) {
          // [class*="col"]
          height: 8px;
        }
      }

      // Low & High
      .low,
      .high {
        // [class*="col"]
        width: 20px
      }

      // Low & On-Track
      .low,
      .on-track {
        // [class*="col"]
        margin-inline-end: 1px;
      }

      // On-Track
      .on-track {
        // [class*="col"]
        width: 58px;
      }
    }

    p {
      font-weight: map-get($text-weights, semibold);
      line-height: $line-height-md;
      color: map-get($co-brand-clrs, content-txt-ttl);
    }
  }

  .icons {
    // [class*="col"]

    .row {

      >[class*="col"] {

        &:not(:first-child) {
          padding-inline-start: 10px;
        }

        .circle {
          // <div>
          display: flex;
          width: 40px;
          height: 40px;
          background-color: $border-clr;
          font-size: map-get($font-sizes, '20');
          color: $white;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          svg {
            width: 24px;
            max-height: 24px;
            height: auto;
          }
        }
      }
    }
  }

  a {
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: map-get($co-brand-clrs, content-trg-secondary-def);
    color: $white;
    align-items: center;
    padding: 10px;
    border-radius: 0 0 $generic-border-radius $generic-border-radius;
    margin-inline: -($rcvy-prog-inline-pad + $rcvy-prog-brdr-width);
    margin-block-end: -$rcvy-prog-brdr-width;

    &:hover:not(.q-btn):not(.q-item):not(.q-tab) {
      text-decoration: none;
    }

    >div {

      // Row Specificity

      // 1st Row
      &:nth-child(-n+2) {
        font-size: map-get($font-sizes, '22');
      }

      // Column Specificity

      // 1st Column
      &:nth-child(2n+1) {
        text-align: end;
      }

      // 2nd Column
      &:nth-child(2n+2) {
        padding-inline: 10px;
      }

      // Element Specificity

      // 1st Child
      &:first-child {
        line-height: 32px;
      }

      // 2nd Child
      &:nth-child(2) {
        line-height: 0;
      }

      // 3rd Child
      &:nth-child(3) {
        opacity: 0.5;
        font-weight: map-get($text-weights, light);
        font-size: map-get($font-sizes, '16');
        line-height: 20px;
      }
    }
  }

  @media (min-width: 350px) {

    .flex-br {
      // <div>
      display: none;
    }
  }
}
</style>
