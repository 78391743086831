<script lang="ts" setup>
import {
  computed,
  ref
} from 'vue';
import { useQuasar } from 'quasar';
import { useRoute } from 'vue-router';
import {
  useDashboardStore,
  useMessagesStore,
  useSessionStore
} from '@/store';
import { formatName } from '@/utils/interfaceUtils';
import { loadPatientInfoAndTasks } from 'src/pages/TheDataHydrator';
import Svg from '@/components/Svg.vue';
import { mymobilityFor } from '@/assets';
import Wordmark from 'src/components/header/MymobilityWhWordmark.vue';
import EocButton from 'src/components/header/EocButton.vue';
import EocModal from 'src/components/header/EocModal.vue';
import NameAvatar from '@/components/NameAvatar.vue';
import Navigation from 'src/components/header/Navigation.vue';
import CoBranding from '@/components/header/CoBranding.vue';

const $q = useQuasar(),
  $route = useRoute();

const dashboardStore = useDashboardStore(),
  messagesStore = useMessagesStore(),
  sessionStore = useSessionStore();

const navIsOpen = ref(false),
  showEocModal = ref(false);

const coBrandingEnabled = computed(() => sessionStore.isCobrandingEnabled),
  logo = computed(() => sessionStore.CobrandedImage('navigation-logo'));

const authenticated = computed(() => {
  return sessionStore.isAuthenticated
    && sessionStore.user?.hasFullAccess
    && $route.name !== 'SelectCarePlan'
    && $route.name !== 'AboutYourData';
});

const unreadMsgCt = computed(() => messagesStore.unreadMessagesCount);

const name = computed(() => {
  const user = {
    firstName: sessionStore.user?.firstName,
    lastName: sessionStore.user?.lastName
  };

  return sessionStore.user ? formatName(user) : ''
});

const avatarUrl = computed(() => `data:image/png;base64,${sessionStore.user?.avatarBase64}`),
  hasAvatar = computed(() => sessionStore.user?.avatarBase64 !== null);

if (authenticated.value) {
  loadPatientInfoAndTasks(sessionStore, messagesStore, dashboardStore, $q);
}
</script>

<template>
  <q-header>
    <div class="relative-position bs-container">

      <!-- Curve -->
      <div class="mask">
        <div class="oval" />
      </div>

      <!-- Bar -->
      <div :class="['row q-header-bar', { 'not-auth': !authenticated }]">

        <!-- Not Authenticated -->
        <div v-if="!authenticated" class="col-auto row no-wrap items-center text-content-grd-bg-content">

          <!-- Co-Branding -->
          <CoBranding v-if="coBrandingEnabled" :authenticated="authenticated" :logo="logo ? logo : ''" />

          <!-- mymobility Wordmark -->
          <Wordmark v-else :authenticated="authenticated" width="165" />
        </div>

        <!-- Authenticated -->
        <template v-else>

          <!-- Left -->
          <div class="col row items-center">

            <!-- Navigation (displays xs [0px] thru sm [1023px]) -->
            <div class="col-auto self-stretch lt-md navigation">
              <zbm-btn @click="navIsOpen = !navIsOpen" :outline="false" :rounded="false"
                text-color="content-grd-bg-content">

                <!-- Nav is Closed -->
                <template v-if="!navIsOpen">

                  <!-- Icon -->
                  <q-icon name="fa-regular fa-bars" size="32px" />

                  <!-- Count -->
                  <div v-if="unreadMsgCt" class="unread-msg-ct">
                    {{ unreadMsgCt }}
                  </div>
                </template>

                <!-- Nav is Open -->
                <template v-else>

                  <!-- Icon -->
                  <q-icon name="fa-regular fa-xmark" size="32px" />
                </template>
              </zbm-btn>

              <!-- Header Navigation -->
              <Navigation v-model="navIsOpen" :avatarUrl="avatarUrl" :has-avatar="hasAvatar"
                :is-piq-and-disabled="dashboardStore.patientConfig?.isDischarged" :name="name"
                :unread-msg-ct="unreadMsgCt" :my-stats-route-name="dashboardStore.myStatsRouteName" />
            </div>

            <!-- Branding (displays md [1024px] & wider) -->
            <div class="col-auto gt-sm">

              <!-- Co-Branding -->
              <CoBranding v-if="coBrandingEnabled" :authenticated="authenticated" :logo="logo ? logo : ''" />

              <!-- mymobility Wordmark -->
              <Wordmark v-else :authenticated="authenticated" class="text-content-grd-bg-content" width="110" />
            </div>
          </div>

          <!-- Center -->
          <div :class="['col-auto column justify-end items-center', { 'co-brand-center': coBrandingEnabled }]">

            <!-- Branding (displays xs [0px] thru sm [1023px]) -->
            <div class="col-auto lt-md">

              <!-- Co-Branding -->
              <CoBranding v-if="coBrandingEnabled" :authenticated="authenticated" :logo="logo ? logo : ''" />

              <!-- mymobility Wordmark -->
              <Wordmark v-else :authenticated="authenticated" class="text-content-grd-bg-content" width="110" />
            </div>
            <div class="flex-br" />

            <!-- EOC Navigation -->
            <div class="col-auto eoc-navigation">

              <!-- Button -->
              <EocButton @click="showEocModal = true" />

              <!-- Modal -->
              <EocModal v-model="showEocModal" />
            </div>
          </div>

          <!-- Right -->
          <div class="col row items-center justify-end">

            <!-- User Name & Avatar -->
            <NameAvatar avatar-icon-font-size="22px" avatar-icon-top-margin="4px" avatar-size="28px"
              :avatar-url="avatarUrl" color="content-grd-bg-content" class="gt-sm" :has-avatar="hasAvatar"
              href="#/Settings" is="a" margin="mr-22" :name="name" name-font-size="font-size-18" :on-right="true" />
          </div>
        </template>
      </div>
    </div>
  </q-header>
</template>

<style lang="scss" scoped></style>
