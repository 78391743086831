<script setup lang="ts">
import { useQuasar } from 'quasar';
import {
  useDashboardStore,
  useMessagesStore
} from 'src/store';
import {
  computed,
  onMounted,
  watch
} from 'vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getCareTeam } from 'src/api/endpoints/messagesWeb';
import { type User } from 'src/types/interfaces';
import { formatName } from 'src/utils/interfaceUtils';
import Box from 'src/components/BoxContainer.vue';
import NameAvatar from '@/components/NameAvatar.vue';

const $q = useQuasar();

const dashboardStore = useDashboardStore(),
  messagesModule = useMessagesStore();

const careTeam = computed(() => messagesModule.careTeam),
  careTeamId = computed(() => dashboardStore.patient?.careTeamId);

async function loadCareTeam() {
  if (careTeamId.value) {
    const response = await handleApiRequest(() => getCareTeam(careTeamId.value as number), null, $q);

    if (response?.result) {
      messagesModule.setCareTeam(response.result);
    }
  }
}

function getAvatar(user: User) {
  if (!user.hasAvatarImage) return undefined;

  return `data:image/png;base64,${user.avatarBase64}`;
}

watch(() => careTeamId.value, () => {
  loadCareTeam();
});

onMounted(() => {
  loadCareTeam();
  document.querySelectorAll('html')[0].scrollTop = 0;
});
</script>

<template>
  <zbm-skeleton-loader height="338px" :loading="careTeam?.length == 0" width="100%">
    <Box class="your-team">

      <!-- Title -->
      <p class="your-team__title">{{ $t('messages_care_team') }}</p>

      <!-- Clinician(s) -->
      <NameAvatar v-for="(clinician, i) in careTeam"
                  :avatar-icon-border="!clinician.hasAvatarImage"
                  avatar-icon-color="secondary"
                  avatar-icon-font-size="20px"
                  avatar-icon-name="fa-solid fa-user-doctor"
                  avatar-size="34px"
                  :avatar-url="getAvatar(clinician)"
                  color="body"
                  :has-avatar="clinician.hasAvatarImage"
                  is="div"
                  :key="i"
                  margin="mr-15"
                  :name="formatName(clinician)" />
    </Box>
  </zbm-skeleton-loader>
</template>

<style lang="scss" scoped>
@use 'sass:math';

.your-team { // .box

  > .row,
  &__title {

    &:not(:last-child) {
      margin-block-end: 20px;
    }
  }

  // Title
  &__title { // <p>
    font-weight: map-get($text-weights, bold);
    color: $dark-teal;
  }

  // Clinician Name
  :deep(.text-body) {
    word-break: break-word;
  }
}

// Care Team Dialog
.q-dialog__inner {

  // Title
  .your-team__title {
    font-size: $h3-font-size;
    line-height: math.div(map-get($line-heights, '26'), $h3-font-size);
  }
}

// "auxiliary" Route Slot
.dashboard__left {

  .your-team {
    padding: 20px 18px;
    border: 1px solid $border-clr;

    // Title
    &__title {
      font-size: $h5-font-size;
      line-height: math.div(map-get($line-heights, '26'), $h5-font-size);
    }
  }
}
</style>
