<script setup lang="ts">
import { useSurveyStore } from 'src/store';
import { SurveyTypeEnum } from 'src/constants/survey';
import { i18n } from '@/plugins/i18n';
import { computed } from 'vue';

const $i18nt = i18n.global.t;
const surveyModule = useSurveyStore();

const surveyThankYouTitle = computed(() => {
  if (
    surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.Pain_and_Opioid_Pre_Op ||
    surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.Pain_and_Opioid_Post_Op
  ) {
    return $i18nt('checkin_complete_title').toString();
  } else {
    return $i18nt('survey_completed').toString();
  }
});

const surveyThankYouBody = computed(() => {
  if (surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.Pain_and_Opioid_Pre_Op) {
    return reFormatString($i18nt('checkin_complete_preop_body').toString());
  } else if (
    surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.Pain_and_Opioid_Post_Op
  ) {
    return reFormatString($i18nt('checkin_complete_body').toString()).replace('\\', '');
  } else {
    return $i18nt('survey_submit').toString();
  }
});

const surveyThankYouList = computed(() => {
  if (
    surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.Pain_and_Opioid_Pre_Op ||
    surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.Pain_and_Opioid_Post_Op
  ) {
    return reFormatString($i18nt('checkin_disclaimer_painmgmt').toString());
  }

  return '';
});

function reFormatString(str: string) {
  return str.replace(/\\n/g, '<br />');
}
</script>

<template>
  <div class="row bs-gutter justify-center text-center survey__submit">
    <div class="col-auto">
      <h1 class="mb-50">{{ surveyThankYouTitle }}</h1>
      <q-icon class="mb-50" color="primary" name="fa-light fa-circle-check" size="132px" />
      <p v-html="surveyThankYouBody" />
      <p v-if="surveyThankYouList" v-html="surveyThankYouList" class="text-left" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.survey {

  // Submit
  &__submit {

    >[class*="col"] {
      flex-basis: 100%;
      width: 100%;
      max-width: 600px;
    }

    p {
      font-size: map-get($font-sizes, '20');
      line-height: $line-height-md;
    }
  }
}
</style>
