<script setup lang="ts">
import { computed, ref } from 'vue';
import { i18n } from '@/plugins/i18n';
import { useRouter } from 'vue-router';
import {
  useDashboardStore,
  useGeneralPurposeModalStore,
  useLocalizationStore,
  useSessionStore,
} from 'src/store';
import {
  AIProgressRanks,
  ClientFeatureType,
  RcvyProgDataCollStatus,
  StatisticTypeEnum,
  TaskType
} from 'src/constants/enums';
import { type Task } from 'src/types/webContracts';
import { reformatString } from 'src/utils/stringUtils';
import TheTodaysTasksCategory from 'src/pages/DashboardHome/components/TheTodaysTasksCategory.vue';
import TheTodaysTasksPriority from 'src/pages/DashboardHome/components/TheTodaysTasksPriority.vue';
import MyProgressMetricGraph from 'src/pages/DashboardHome/components/MyProgressMetricGraph.vue';
import { createDecisionModal } from 'src/utils/generalPurposeModalFactory';
import { toUserFacingDate } from 'src/utils/dateUtil';
import Box from 'src/components/BoxContainer.vue';
import MyProgressMoreBtn from '@/pages/DashboardHome/components/MyProgressMoreBtn.vue';
import Svg from '@/components/Svg.vue';
import {
  pain,
  piqKneeRom,
  routines
} from '@/assets';
import MyRecoveryProgress from '@/pages/DashboardHome/components/MyRecoveryProgress.vue';

const dashboardStore = useDashboardStore();

const sessionStore = useSessionStore();
const localizationStore = useLocalizationStore();
const router = useRouter();
const generalPurposeModalStore = useGeneralPurposeModalStore();

const numberOfSessions = ref(3);
const timeOfSessions = ref(5);
const tasks = ref([] as Array<Task>);
const videoVisitDate = ref('Tomorrow');

const educationMoreInfoVisible = ref(false);
const surveyMoreInfoVisible = ref(false);
const exerciseMoreInfoVisible = ref(false);
const isPIQandDisabled = computed(() => dashboardStore.patientConfig?.isDischarged);
const isLoading = computed(() => dashboardStore.isLoading);
const taskType = computed(() => TaskType);
const lastPainScore = computed(() => dashboardStore.patient?.painAndOpioidData.lastPainScore);
const progressWidget = computed(() => dashboardStore.patient?.progressWidget);
const canSelfReportPainRating = computed(() => isPostSurgery.value && !isSelfReportLocked.value);
const isSelfReportLocked = computed(() => !dashboardStore.patient?.painAndOpioidData?.checkinAllowed);
const educationMoreInfo = computed(() => reformatString(i18n.global.t('home_adherence_education').toString()));
const surveyMoreInfo = computed(() => reformatString(i18n.global.t('home_adherence_survey').toString()));
const routineMoreInfo = computed(() => reformatString(i18n.global.t('home_adherence_exercises').toString()));
const todaysDate = computed(() => dashboardStore.todaysDate);
const hasMyRecoveryProgress = computed(() => dashboardStore.patientConfig?.features?.patientProgressFeatureConfig?.isProgressWidgetVisible ?? false);

const lastPainRatingDate = computed(() => {
  const date = dashboardStore.patient?.painAndOpioidData.lastCheckin;
  if (date) {
    return toUserFacingDate(date, false, localizationStore.locale);
  }
  return i18n.global.t('pain_rating_empty');
});

const isAdHocPainTrackingEnabled = computed(() => {
  let result = false;
  if (sessionStore && sessionStore.user && sessionStore.user.clientFeatures) {
    result = sessionStore.user.clientFeatures.includes(ClientFeatureType.PainTracking) && isPostSurgery.value;
  }
  return result;
});

const isPostSurgery = computed(() => {
  let result = false;
  if (dashboardStore && dashboardStore.patient && dashboardStore.patient.daysSinceUntilSurgery) {
    result = dashboardStore.patient.daysSinceUntilSurgery as number > 0;
  }
  return result;
});

const displayPIQTasks = computed(() => {
  var taskCount = piqTasks.value ? piqTasks.value.length : 0;
  return taskCount > 0;
});

const piqTasks = computed(() => {
  let result: Task[] = [];
  if (dashboardStore && dashboardStore.tasks) {
    const tasks = filterTasks(TaskType.piq, dashboardStore.tasks);
    tasks?.forEach((t) => (t.name = i18n.global.t(t.name).toString()));
    result = tasks;
  }
  return result;
});

const displayEducations = computed(() => dashboardStore?.patientConfig?.educations?.isAdherenceEnabled ?? false);
const displaySurveys = computed(() => dashboardStore?.patientConfig?.surveys?.isAdherenceEnabled ?? false);
const displayRoutines = computed(() => dashboardStore?.patientConfig?.routines?.isAdherenceEnabled ?? false);

const educationTasks = computed(() => {
  const tasks = dashboardStore.tasks;
  return filterAndSortTasks(TaskType.education, tasks);
});

const surveyTasks = computed(() => {
  const tasks = dashboardStore.tasks;
  return filterAndSortTasks(TaskType.survey, tasks);
});

const routineTasks = computed(() => {
  const tasks = dashboardStore.tasks;
  return filterAndSortTasks(TaskType.routine, tasks);
});

const educationPercentageComplete = computed(() => {
  const stats = dashboardStore.statisticSummaries['Wearable'];
  const result =
    stats?.details.find(
      (x) => x.dataTypeKey === StatisticTypeEnum.EducationProgress && x.periodType === 'All-Days'
    )?.dataValue || 0;
  const percentage = Math.round(result * 100);
  return percentage;
});

const surveyPercentageComplete = computed(() => {
  const stats = dashboardStore.statisticSummaries['Wearable'];
  const result =
    stats?.details.find(
      (x) => x.dataTypeKey === StatisticTypeEnum.SurveyProgress && x.periodType === 'All-Days'
    )?.dataValue || 0;
  const percentage = Math.round(result * 100);
  return percentage;
});

const routinePercentageComplete = computed(() => {
  const stats = dashboardStore.statisticSummaries['Wearable'];
  const result =
    stats?.details.find(
      (x) => x.dataTypeKey === StatisticTypeEnum.ExerciseProgress && x.periodType === 'All-Days'
    )?.dataValue || 0;
  const percentage = Math.round(result * 100);
  return percentage;
});

const daysSinceUntilSurgery = computed(() => {
  let result: number | null = null;
  if (dashboardStore && dashboardStore.patient && dashboardStore.patient.daysSinceUntilSurgery !== undefined) {
    result = dashboardStore.patient.daysSinceUntilSurgery;
  }
  return result;
});

const daysUntilSinceSurgeryQualifier = computed(() => {
  let result = '';

  const days: number | null = daysSinceUntilSurgery.value;
  if (days) {
    if (days > 0) {
      result = (
        days === 1
          ? i18n.global.t('days_since_surgery_pluralization_one')
          : i18n.global.t('days_since_surgery_pluralization_many')
      ).toString();
    } else if (days < 0) {
      result = (
        days === 1
          ? i18n.global.t('days_until_surgery_pluralization_one')
          : i18n.global.t('days_until_surgery_pluralization_many')
      ).toString();
    } else {
      result = i18n.global.t('days_until_surgery_pluralization_many').toString();
    }
  }
  return result;
});

const dayString = computed(() => {
  let result = '';
  const sinceUntilSurgery: number | null = daysSinceUntilSurgery.value;
  if (sinceUntilSurgery) {
    result = Math.abs(sinceUntilSurgery).toString();
  }
  return result;
});

function filterTasks(taskType: TaskType, tasks: Task[]): Task[] {
  return tasks.filter((t: any) => t.taskType.valueOf() === taskType.valueOf());
}

function filterAndSortTasks(taskType: TaskType, tasks: Task[] | null): Task[] {
  let result: Task[] = [];
  if (tasks) {
    const categoryTasks = tasks.filter((x) => x.taskType.valueOf() === taskType.valueOf());

    // Make sure that the completed tasks are grouped at the bottom of the list
    const incompleteTasks = categoryTasks.filter((x) => !x.isComplete);
    const completeTasks = categoryTasks.filter((x) => x.isComplete);

    result = [...incompleteTasks, ...completeTasks];
  }

  return result;
}

function onPainRatingClicked() {
  if (canSelfReportPainRating.value) {
    router.push({ name: 'PainRating' });
  } else if (isSelfReportLocked.value) {
    const descString = i18n.global.t('pain_rating_lock_modal_text')
      .toString()
      .replace(/\\n/gi, '<br />')
      .replace(/\\/gi, '');
    const info = createDecisionModal(
      i18n.global.t('pain_rating_lock_modal_title').toString(),
      descString
    );
    generalPurposeModalStore.setModal(info);
  }
}
</script>

<template>

  <!-- If patient is NOT PIQ & discharged. -->
  <div v-if="!isPIQandDisabled">

    <!-- Heading -->
    <div class="row items-center dash-home__heading">

      <!-- Title -->
      <div class="col">
        <h1>
          <zbm-skeleton-loader height="34px"
                               :loading="isLoading"
                               width="100%">
            {{ $t('home_tasks') }}
          </zbm-skeleton-loader>
        </h1>
      </div>
      <div class="flex-br" />

      <!-- Date -->
      <div class="col-auto">
        <zbm-skeleton-loader height="24px"
                             :loading="isLoading"
                             width="100px">
          {{ todaysDate }}
        </zbm-skeleton-loader>
      </div>
    </div>

    <div class="row dash-home__tasks">
      <div class="col">

        <!-- PIQ Tasks -->
        <zbm-skeleton-loader v-if="displayPIQTasks"
                             class="mb-18"
                             height="70px"
                             :loading="isLoading"
                             width="100%">
          <TheTodaysTasksCategory :label="$t('piq_account_setup_tasks')"
                                  :tasks="piqTasks"
                                  :taskType="taskType.piq">
            <template #icon>
              <Svg :view-box="piqKneeRom.viewBox"
                   :vector-paths="piqKneeRom.vectorPaths" />
            </template>
          </TheTodaysTasksCategory>
        </zbm-skeleton-loader>

        <!-- Education Tasks -->
        <zbm-skeleton-loader class="mb-18"
                             height="70px"
                             :loading="isLoading"
                             width="100%"
                             v-if="displayEducations">
          <TheTodaysTasksCategory :label="$t('gen_education')"
                                  :tasks="educationTasks"
                                  :taskType="taskType.education"
                                  data-testid="educationTasks">
            <template #icon>
              <q-icon name="fa-solid fa-graduation-cap"
                      size="27px" />
            </template>
          </TheTodaysTasksCategory>
        </zbm-skeleton-loader>

        <!-- Surveys Category -->
        <zbm-skeleton-loader class="mb-18"
                             height="70px"
                             :loading="isLoading"
                             width="100%"
                             v-if="displaySurveys">
          <TheTodaysTasksCategory :label="$t('gen_surveys')"
                                  :tasks="surveyTasks"
                                  :taskType="taskType.survey"
                                  data-testid="surveyTasks">
            <template #icon>
              <q-icon name="fa-solid fa-clipboard-list"
                      size="29px" />
            </template>
          </TheTodaysTasksCategory>
        </zbm-skeleton-loader>

        <!-- Routines Category -->
        <zbm-skeleton-loader height="70px"
                             :loading="isLoading"
                             width="100%"
                             v-if="displayRoutines">
          <TheTodaysTasksCategory :label="$t('gen_routines')"
                                  :tasks="routineTasks"
                                  :taskType="taskType.routine"
                                  data-testid="routineTasks">
            <template #icon>
              <Svg :view-box="routines.viewBox"
                   :vector-paths="routines.vectorPaths" />
            </template>
          </TheTodaysTasksCategory>
        </zbm-skeleton-loader>
      </div>
    </div>

    <!-- My Recovery Progress -->
    <div v-if="hasMyRecoveryProgress"
         class="row dash-home__recovery-prog" data-testid="myRecoveryProgress">
      <div class="col">

        <!-- Title -->
        <h3 class="mb-16">{{ $t('my_recovery_progress') }}</h3>

        <!-- Widget -->
        <MyRecoveryProgress :data-status="progressWidget?.dataCollectingStatus"
                            :data-string="progressWidget?.dataAvailableDisplayString"
                            :metrics="progressWidget?.metrics" />
      </div>
    </div>

    <!-- Today's Tasks Priority - Pain Rating  -->
    <div v-if="isAdHocPainTrackingEnabled && !canSelfReportPainRating"
         class="row dash-home__priority" data-testid="painRatingDiv">
      <div class="col">
        <TheTodaysTasksPriority :click="onPainRatingClicked"
                                :label="$t('pain_rating_title')"
                                :loading="isLoading"
                                :locked="isSelfReportLocked"
                                :dataTestId="`painRatingPriority`" >

          <!-- Icon -->
          <template #icon>
            <Svg :view-box="pain.viewBox"
                 :vector-paths="pain.vectorPaths" />
          </template>

          <!-- Label -->
          <template #default>
            <span v-if="lastPainScore"
                  class="text-weight-bold">
              {{ `${lastPainScore}/10` }}&nbsp;&nbsp;
            </span>
            <span class="text-content-trg-secondary-def">{{ lastPainRatingDate }}</span>
          </template>
        </TheTodaysTasksPriority>
      </div>
    </div>

    <!-- Today's Tasks Priority - Daily Walk Goal -->
<!--    <div class="row dash-home__priority">
      <div class="col col-sm-6">
        <TheTodaysTasksPriority :label="$t('gait_daily_walk_goal')">

          &lt;!&ndash; Icon &ndash;&gt;
          <template #icon>
            <q-icon color="primary"
                    name="fa-solid fa-walking"
                    size="31px" />
          </template>

          &lt;!&ndash; Label &ndash;&gt;
          <template #default>
            <span class="text-weight-bold">{{ numberOfSessions }}</span> {{ $t('gait_sessions') }},<br />
            <span class="text-weight-bold">{{ timeOfSessions }}</span> {{ $t('gait_minutes_each') }}
          </template>
        </TheTodaysTasksPriority>
      </div>
    </div>-->

    <!-- Video Visit Coming Up -->
<!--    <div class="row dash-home__priority">
      <div class="col ">
        <TheTodaysTasksPriority class="coming-up"
                                :label="$t('videovisit_comingup_heading')">

          &lt;!&ndash; Icon &ndash;&gt;
          <template #icon>
            <q-icon color="primary"
                    name="fa-regular fa-calendar-alt"
                    size="31px" />
          </template>

          &lt;!&ndash; Label &ndash;&gt;
          <template #default>
            <span>{{ $t('videovisit_title') }}</span><span class="text-dark-teal">{{ videoVisitDate }}</span>
          </template>
        </TheTodaysTasksPriority>
      </div>
    </div>-->

    <!-- My Progress -->
    <div class="row dash-home__progress" data-testid="myProgress">
      <div class="col">

        <!-- Heading -->
        <div class="row items-center dash-home__progress-heading">

          <!-- Title -->
          <div class="col-auto title">
            <zbm-skeleton-loader height="26px"
                                 :loading="isLoading"
                                 width="135px">
              <h3>{{ $t('home_adherence_progress') }}</h3>
            </zbm-skeleton-loader>
          </div>
          <div class="flex-br" />

          <!-- Days -->
          <div class="col days">
            <zbm-skeleton-loader height="36px"
                                 :loading="isLoading"
                                 width="100%">
              <p><span>{{ dayString }}</span> {{ daysUntilSinceSurgeryQualifier }}</p>
            </zbm-skeleton-loader>
          </div>
        </div>

        <!-- Metrics -->
        <div class="row bs-gutter justify-center dash-home__progress-metrics">

          <!-- Education -->
          <div v-if="displayEducations" class="col-auto">
            <zbm-skeleton-loader height="222px"
                                 :loading="isLoading"
                                 width="130px">

              <!-- Title-->
              <h4 class="text-weight-regular mb-20">{{ $t('gen_education') }}</h4>

              <!-- Progress -->
              <MyProgressMetricGraph :value="educationPercentageComplete" />

              <!-- More Info Button -->
              <div>
                <MyProgressMoreBtn @click="educationMoreInfoVisible = !educationMoreInfoVisible"
                                   :open="educationMoreInfoVisible" data-testid="myProgressMoreInfoButton" />
              </div>

              <!-- About -->
              <q-expansion-item v-model="educationMoreInfoVisible"
                                :duration="0"
                                :hide-expand-icon=true
                                :data-testid="educationMoreInfo">
                {{ educationMoreInfo }}
              </q-expansion-item>
            </zbm-skeleton-loader>
          </div>

          <!-- Surveys -->
          <div v-if="displaySurveys" class="col-auto">
            <zbm-skeleton-loader height="222px"
                                 :loading="isLoading"
                                 width="130px">

              <!-- Title-->
              <h4 class="text-weight-regular mb-20">{{ $t('gen_surveys') }}</h4>

              <!-- Progress -->
              <MyProgressMetricGraph :value="surveyPercentageComplete" />

              <!-- More Info Button -->
              <div>
                <MyProgressMoreBtn @click="surveyMoreInfoVisible = !surveyMoreInfoVisible"
                                   :open="surveyMoreInfoVisible" data-testid="surveyMoreInfoButton"/>
              </div>

              <!-- About -->
              <q-expansion-item v-model="surveyMoreInfoVisible"
                                :duration="0"
                                :hide-expand-icon=true>
                {{ surveyMoreInfo }}
              </q-expansion-item>
            </zbm-skeleton-loader>
          </div>

          <!-- Exercise -->
          <div v-if="displayRoutines" class="col-auto">
            <zbm-skeleton-loader height="222px"
                                 :loading="isLoading"
                                 width="130px">

              <!-- Title-->
              <h4 class="text-weight-regular mb-20">{{ $t('gen_exercises_ios') }}</h4>

              <!-- Progress -->
              <MyProgressMetricGraph :value="routinePercentageComplete" data-testid="routinePercentageComplete"/>

              <!-- More Info Button -->
              <div>
                <MyProgressMoreBtn @click="exerciseMoreInfoVisible = !exerciseMoreInfoVisible"
                                   :open="exerciseMoreInfoVisible" data-testid="exerciseMoreInfoButton" />
              </div>

              <!-- About -->
              <q-expansion-item v-model="exerciseMoreInfoVisible"
                                :duration="0"
                                :hide-expand-icon=true>
                {{ routineMoreInfo }}
              </q-expansion-item>
            </zbm-skeleton-loader>
          </div>
        </div>

        <!-- About (metrics) -->
        <div class="row bs-gutter dash-home__progress-about">
          <div class="col">

            <!-- Education -->
            <q-expansion-item v-model="educationMoreInfoVisible"
                              :duration="0"
                              group="aboutMetrics"
                              :hide-expand-icon=true>
              {{ educationMoreInfo }}
            </q-expansion-item>

            <!-- Survey -->
            <q-expansion-item v-model="surveyMoreInfoVisible"
                              :duration="0"
                              group="aboutMetrics"
                              :hide-expand-icon=true>
              {{ surveyMoreInfo }}
            </q-expansion-item>

            <!-- Exercise -->
            <q-expansion-item v-model="exerciseMoreInfoVisible"
                              :duration="0"
                              group="aboutMetrics"
                              :hide-expand-icon=true>
              {{ routineMoreInfo }}
            </q-expansion-item>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- If patient IS PIQ & discharged -->
  <div v-else
       class="row dash-home__defeatured">
    <div class="col">
      <Box :box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.grey"
           :box-padding="$zb.enums.BoxPaddingEnum.p303055"
           class="text-center">
        <img src="/img/defeatured.svg" alt="" />
        <h2 class="text-secondary">{{ i18n.global.t('patient_discharge_stats') }}</h2>
        <p>{{ i18n.global.t('patient_discharge_stats_desc') }}</p>
      </Box>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dash-home {
  $dash-home-border: 1px solid $border-clr;

  // Heading
  &__heading { // .row
    margin-block-end: 21px;
  }

  // Today's Tasks
  &__tasks { // .row
    margin-block-end: 20px;
  }

  // My Recovery Progress
  &__recovery-prog { // .row
    padding-block-start: 20px;
    border-block-start: $dash-home-border;
    margin-block-end: 20px;
  }

  // Today's Tasks Priority
  &__priority { // .row
    padding-block-end: 20px;
    border-block-start: $dash-home-border;
    margin-block-end: 25px;
  }

  // My Progress
  &__progress { // .row
    padding-block-start: 20px;
    border-block-start: $dash-home-border;
    margin-block-end: 20px;

    :deep(.q-item) {
      display: none;
    }

    :deep(.q-expansion-item__content) {
      font-size: map-get($font-sizes, '14');
      line-height: $line-height-lg;
      word-wrap: break-word;
      text-align: left;
      padding-block-start: 16px;
    }

    // Heading
    &-heading { // .row
      margin-block-end: 44px;

      // Title
      .title { // [class*="col"]
        padding-block: 5px;
        padding-inline-end: 18px;
      }

      // Days
      .days { // [class*="col"]
        font-size: map-get($font-sizes, '14');
        line-height: $line-height-sm;

        span {
          font-weight: map-get($text-weights, bold);
          font-size: 24px;
          line-height: 1;
        }
      }
    }

    // Metrics
    &-metrics { // .row

      > [class*="col"] {
        text-align: center;
        margin-block-end: 16px;

        &:last-child {
          margin-block-end: 0;
        }
      }

      .q-circular-progress {
        margin-block-end: 19px;
        margin-inline: auto;
      }

      :deep(.q-skeleton) {
        margin-inline: auto;
      }
    }
  }

  // Discharged PIQ Patient
  &__defeatured { // .row

    img {
      max-width: 400px;
      width: 100%;
      margin: 0 auto 30px;
    }
  }

  @media (max-width: 359px) {

    // My Progress
    &__progress { // .row

      // Metrics
      &-metrics > [class*="col"] {
        flex: 1000 1 0;
      }

      // About
      &-about { // .row
        display: none;
      }
    }
  }

  @media (min-width: 360px) {

    // Metrics
    &__progress-metrics { // .row

      > [class*="col"] {
        margin-block-end: 0;
      }

      // About
      :deep(.q-expansion-item) {
        display: none;
      }
    }
  }

  @media (min-width: 400px) {

    // Heading
    &__heading { // .row

      > [class*="col"] {
        padding-inline: 20px;

        &:first-child {
          padding-inline-start: 0;
        }

        &:last-child {
          padding-inline-end: 0;
        }
      }

      .flex-br {
        display: none;
      }
    }

    // My Progress
    &__progress { // .row

      // Title
      &-heading { // .row

        // Title
        .title { // [class*="col"]
          border-inline-end: 1px solid $border-clr;
        }

        .flex-br {
          display: none;
        }

        // Days
        .days { // [class*="col"]
          padding-inline-start: 18px;
        }
      }
    }
  }
}
</style>
