<template>
  <q-page padding class="container main completed-routine">
    <div class="row justify-center">
      <Box class="col-auto relative-position" :boxWidth="$zb.enums.BoxWidthEnum.lg"
        :boxPadding="$zb.enums.BoxPaddingEnum.p453565">
        <!-- Heading Background -->
        <div class="completed-routine__heading-bkgd"></div>

        <!-- Heading Content -->
        <div class="row justify-center completed-routine__heading-content">
          <div class="col-auto" style="z-index: 1">
            <p>{{ $t('routine_congrats') }}</p>
            <p><q-icon name="fa-light fa-circle-check" /></p>
            <p class="font-weight-bold">{{ $t('routine_complete') }}</p>
          </div>
        </div>

        <!-- Content -->
        <div class="row completed-routine__content">
          <div class="col">
            <!-- Exercises Completed -->
            <div class="row no-gutters align-items-center">
              <!-- Icon -->
              <div class="col-auto icon">
                <Svg :view-box="routines.viewBox" :vector-paths="routines.vectorPaths" />
              </div>

              <!-- Text/Value -->
              <div class="col">{{ $t('vue3_routine_exercises_completed', [completed]) }}</div>
            </div>

            <!-- Routine Rating -->
            <div class="row no-gutters align-items-center">
              <!-- Icon -->
              <div class="col-auto icon"><q-icon name="fa-regular fa-dot-circle" /></div>

              <!-- Text/Value -->
              <div class="col">{{ $t('vue3_routine_rating', [rating]) }}</div>
            </div>
          </div>
        </div>

        <!-- Done Button -->
        <div class="row justify-center">
          <div class="col-auto">
            <zbm-btn @click="onDoneClick" :color="$zb.enums.ButtonStdColorEnum.accent"
              :height="$zb.enums.ButtonHeightEnum.lg" :width="$zb.enums.ButtonWidthEnum.w270">{{ $t('done') }}</zbm-btn>
          </div>
        </div>
      </Box>
    </div>
  </q-page>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useSessionStore } from 'src/store';
import Box from 'src/components/BoxContainer.vue';
import { onMounted } from 'vue';
import Svg from '@/components/Svg.vue';
import { routines } from '@/assets';

const sessionStore = useSessionStore();
const router = useRouter();

const props = defineProps({
  completed: {
    type: Number,
    required: true,
  },
  rating: {
    type: String,
    required: true,
  },
});

onMounted(async () => {
  document.querySelectorAll('html')[0].scrollTop = 0;
  sessionStore.setIsHeaderVisible(true);
});

async function onDoneClick() {
  router.push({ name: 'Dashboard.Home' });
}
</script>
