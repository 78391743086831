<script setup lang="ts">
import {
  defineProps,
  type PropType
} from 'vue';
import { CustomCheckboxVariantEnum } from 'src/constants/enums';

const props = defineProps({
  disable: {
    type: Boolean,
    default: false
  },
  falseValue: {},
  indeterminateValue: {},
  leftLabel: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
  },
  name: {
    type: String
  },
  trueValue: {},
  val: {},
  variant: {
    type: String as PropType<CustomCheckboxVariantEnum>
  }
});

const syncValue = defineModel({
  type: Boolean,
  default: false
});
</script>

<template>
  <q-checkbox v-model="syncValue" checked-icon="fa-regular fa-check" :class="props.variant"
    :color="props.variant === CustomCheckboxVariantEnum.survey ? 'survey' : 'body'" :disable="props.disable"
    :false-value="props.falseValue" indeterminate-icon="fa-regular fa-minus" :indeterminate-value="props.indeterminateValue"
    :left-label="props.leftLabel" :label="props.label" :name="props.name" :true-value="props.trueValue"
    :unchecked-icon="props.variant === CustomCheckboxVariantEnum.survey ? 'fa-regular fa-check' : ' '" :val="props.val">
    <slot />
  </q-checkbox>
</template>

<style lang="scss" scoped>
:deep(.text-survey.q-checkbox__inner--truthy) {
  color: $secondary !important;
}
</style>
