<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick">

      <!-- Title -->
      <template v-slot:title>{{ $t('settings') }}</template>

      <!-- Heading Text/Content -->
      <template v-slot:heading>{{ $t('settings_legal_and_privacy') }}</template>

      <!-- View Unique Content -->
      <template v-slot:default>

        <!-- Legal Doc Navigation -->
        <q-list v-if="isCommunicationPreferencesEnabled">
          <NavigationItem :to="{ name: 'Settings.SafetyInformation' }" :label="$t('routine_safety')" data-testid="safetyInformation" />
          <NavigationItem :to="{ name: 'Settings.PrivacyPolicy' }" :label="$t('activate_privacy_policy')" data-testid="privacyPolicy" />
          <NavigationItem :to="{ name: 'CommunicationPreferences', params: { interrupt: false } }" :label="$t('communication')" data-testid="communicationPreferences" />
          <NavigationItem :to="{ name: 'Settings.EULA' }" :label="$t('settings_eula')" data-testid="eula" />
        </q-list>
        <q-list v-else>
          <NavigationItem :to="{ name: 'Settings.SafetyInformation' }" :label="$t('routine_safety')" data-testid="safetyInformation" />
          <NavigationItem :to="{ name: 'Settings.PrivacyPolicy' }" :label="$t('activate_privacy_policy')" data-testid="privacyPolicy" />
          <NavigationItem :to="{ name: 'Settings.EULA' }" :label="$t('settings_eula')" data-testid="eula" />
        </q-list>
      </template>
    </MultiViewLayout>
  </q-page>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import NavigationItem from 'src/components/NavigationItem.vue';
import { getPatientConfig } from 'src/api/endpoints/patientWeb';
import type {
  UserPatientResponse
} from 'src/types/webContracts';

const $q = useQuasar()
const router = useRouter();
const isCommunicationPreferencesEnabled = ref(false);

onMounted(async () => {
  const userPatientResponse = await handleApiRequest(() => getPatientConfig(), null, $q);

  if (userPatientResponse?.result) {
    isCommunicationPreferencesEnabled.value = userPatientResponse.result.features.isCommunicationPreferencesEnabled;
  }
  else {
    isCommunicationPreferencesEnabled.value = false;
  }
});

function onBackClick() {
  router.push({ name: 'Settings' });
}
</script>