<template>
  <div class="row align-items-center justify-center data__details-nav">
    <div class="col-auto">
      <zbm-btn-icon @click="onPrevClick(dateRange.startDate)" icon="fa-light fa-circle-chevron-left" size="24px"
        :buttonHeight="$zb.enums.ButtonHeightEnum.lg" :disabled="disablePrev" color="white" class="text-dark-teal" />
    </div>
    <div class="col-auto range px-20">{{ startDateFormatted }} - {{ endDateFormatted }}</div>
    <div class="col-auto">
      <zbm-btn-icon @click="onNextClick(dateRange.endDate)" icon="fa-light fa-circle-chevron-right" size="24px"
        :buttonHeight="$zb.enums.ButtonHeightEnum.lg" :disabled="disabledNextComp" color="white"
        class="text-dark-teal" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { localizedStatDate } from 'src/utils/dateUtil';
import moment from 'momentCultured';
import { useLocalizationStore } from '@/store';

const localizationStore = useLocalizationStore();

const props = defineProps({
  disablePrev: Boolean,
  disableNext: Boolean
});

const dateRange = defineModel('dateRange', {
  default: () => ({
    startDate: '',
    endDate: '',
  })
})

const init = () => {
  const today = moment().weekday(),
    startDate = moment().subtract(today, 'days').format('YYYY-MM-DD'),
    endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD');

  dateRange.value = {
    startDate,
    endDate,
  };
}

const startDateFormatted = computed(() => {
  return localizedStatDate(dateRange.value.startDate, localizationStore.locale);
});

const endDateFormatted = computed(() => {
  return localizedStatDate(dateRange.value.endDate, localizationStore.locale);
});

const disabledNextComp = computed(() => {
  return props.disableNext || moment().isBetween(dateRange.value.startDate, dateRange.value.endDate);
});

function onPrevClick(start: string) {
  const tempEnd = moment(start).subtract(1, 'days'),
    tempStart = moment(tempEnd).subtract(6, 'days');

  dateRange.value = {
    startDate: moment(tempStart).format('YYYY-MM-DD'),
    endDate: moment(tempEnd).format('YYYY-MM-DD'),
  };
}
function onNextClick(end: string) {
  const tempStart = moment(end).add(1, 'days'),
    tempEnd = moment(tempStart).add(6, 'days');

  dateRange.value = {
    startDate: moment(tempStart).format('YYYY-MM-DD'),
    endDate: moment(tempEnd).format('YYYY-MM-DD'),
  };
}

defineExpose({
  init
})
</script>
