<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { postLogin } from 'src/api/endpoints/loginWeb';
import { useActivationStore, useGeneralPurposeModalStore, useLocalizationStore } from 'src/store';
import { useSessionStore } from '@/store/sessionStore';
import { getForgottenUserName } from 'src/api/endpoints/userWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { GeneralPurposeVariant } from 'src/constants/enums';
import Box from 'src/components/BoxContainer.vue';
import { useRouter } from 'vue-router';
import { i18n } from '@/plugins/i18n';
import { useEventBus } from '@/utils/eventBus';
import { useQuasar, QForm } from 'quasar';
import Svg from '@/components/Svg.vue';
import { mymobilityWordmark } from '@/assets';
import { registerSyntheticMomentCultures } from 'momentCultured';
import { userLogout } from '@/services/userService';
import { getUserSession } from 'src/api/endpoints/userWeb';
import { AuthPolicyEnum } from 'src/constants/authPolicyEnum';

const activationStore = useActivationStore()
const generalPurposeModalStore = useGeneralPurposeModalStore()
const router = useRouter();
const $i18nt = i18n.global.t;
const sessionStore = useSessionStore();
const eventBus = useEventBus();
const $q = useQuasar();

// Reactive data
const isLoading = ref(true);
const loginModel = reactive({
  password: '',
  username: '',
  countryCode: '',
  utcOffset: sessionStore.utcOffset,
});
const inputType = ref(true);
const eyeClosed = ref(false);
const forgottenUsername = ref('');
const showloginInfoModal = ref(false);
const showforgottenUsernameModal = ref(false);
const validationError = ref(false);

// Computed properties
const countryList = computed(() => activationStore.supportedCountries);
const utcOffset = computed(() => sessionStore.utcOffset);
const forgotUsernameOrPasswordRawHtml = computed(() => {
  const forgotUsernameLocalizedString = $i18nt('login_username_lowercased').toString();
  const forgotPasswordLocalizedString = $i18nt('password_lowercased').toString();
  const forgotUsernameRawLink = rawRouterLink(forgotUsernameLocalizedString, 'ForgotUsername');
  const forgotPasswordRawLink = rawRouterLink(forgotPasswordLocalizedString, 'ForgotPassword');
  const forgotUsernameOrPasswordString = $i18nt('vue3_login_forgot_username_or_password', [forgotUsernameRawLink, forgotPasswordRawLink]).toString();

  return forgotUsernameOrPasswordString;
});

// Template Refs
const loginForm = ref<QForm | null>(null);

// Watchers
watch(() => countryList.value.selectedCountryCode, (newVal) => {
  loginModel.countryCode = newVal;
},
  { immediate: true }
);

watch(loginModel, () => {
  if (validationError.value) {
    loginForm.value?.resetValidation();
    validationError.value = false;
  }
});


// Methods
const rawRouterLink = (displayText: string, destination: string): string => {
  const destinationPath = router.getRoutes().find((d) => d.name === destination)?.path;
  let result = '';
  if (destinationPath) {
    result = `<a href="#${destinationPath}">${displayText}</a>`;
  }
  return result;
};

const onSubmitSave = async () => {
  $q.loading.show();

  try {
    const response = await handleApiRequest(() => postLogin(loginModel), null, $q);
    if (response && response.result) {
      sessionStore.setCompletedLogin(true);

      // reload local strings
      const localizationStore = useLocalizationStore();
      await localizationStore.loadLocalizedStrings();
      registerSyntheticMomentCultures(localizationStore.locale);

      const userSession = await getUserSession();
      //Required info for un-registered user who will be redirected to activation process
      const isUnregisteredPatientWith2FA = userSession?.user?.policies?.some(p => p.includes(AuthPolicyEnum.UnregisteredPatientWith2FA));
      if (isUnregisteredPatientWith2FA) {
        activationStore.setRegistrationUsername(loginModel.username);
        activationStore.setRegistrationPassword(loginModel.password);
        activationStore.supportedCountries.selectedCountryCode = loginModel.countryCode;
      }

      router.push({ name: 'Authenticate' });
    }
  } finally {
    $q.loading.hide();
  }

};

// Lifecycle hooks
onMounted(async () => {

  document.querySelectorAll('html')[0].scrollTop = 0;
  (document.querySelectorAll('input:first-child')[0] as HTMLElement).focus();

  sessionStore.setIsHeaderVisible(false);

  isLoading.value = true;
  try {
    if (activationStore.isRecoveringUsername) {
      const response = await handleApiRequest(() => getForgottenUserName(), null, $q);
      forgottenUsername.value = response?.result as string;
      loginModel.username = forgottenUsername.value;

      showforgottenUsernameModal.value = true;
      activationStore.setIsRecoveringUsername(false);
    } else if (activationStore.isRecoveringPassword && activationStore.passwordWasReset) {
      generalPurposeModalStore.setModal({
        title: $i18nt('success').toString(),
        template: $i18nt('reset_password_success').toString(),
        variant: GeneralPurposeVariant.Success,
      });
      activationStore.setIsRecoveringPassword(false);
      activationStore.setPasswordWasReset(false);
    } else if (sessionStore.user) {
      await userLogout($q);
    }
  } finally {
    isLoading.value = false;
  }

});

// Expose properties for template usage
defineExpose({
  loginModel,
  inputType,
  eyeClosed,
  forgottenUsername,
  showloginInfoModal,
  showforgottenUsernameModal,
  countryList,
  utcOffset,
  forgotUsernameOrPasswordRawHtml,
  rawRouterLink,
  onSubmitSave,
});
</script>

<template>
  <q-page class="no-header login-form" padding>
    <div class="row bs-gutter justify-center">

      <!-- Box -->
      <Box :box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.linearGradient"
        :box-brdr-radius="$zb.enums.BoxBorderRadiusEnum.lg" :box-drop-shadow="$zb.enums.BoxDropShadowEnum.xl"
        :box-padding="$zb.enums.BoxPaddingEnum.p505065" :box-width="$zb.enums.BoxWidthEnum.md" class="col-auto">

        <!-- Forgotten Username Modal -->
        <zbm-dialog v-model="showforgottenUsernameModal">

          <!-- Header -->
          <div class="row q-dialog__header">
            <div class="col-auto">

              <!-- Check Circle Icon -->
              <q-icon color="content-assets-hero-def" name="fa-solid fa-circle-check" size="42px" />
            </div>
          </div>

          <!-- Body -->
          <div class="row q-dialog__body">
            <div class="col">
              <h3 class="text-weight-semibold q-mb-lg">{{ $t('success') }}</h3>
              <p>
                {{ $t('forgot_username_modal_text') }}:<br />
                <span class="text-weight-bold">{{ forgottenUsername }}</span>
              </p>
            </div>
          </div>

          <!-- Footer (CTA button(s)) -->
          <div class="row q-dialog__footer">

            <!-- Okay Button -->
            <div class="col-auto">
              <zbm-btn v-close-popup :color="$zb.enums.ButtonStdColorEnum.accentText"
                :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_okay')" :data-testid="okayButton"/>
            </div>
          </div>
        </zbm-dialog>

        <!-- Heading -->
        <div class="row no-wrap bs-gutter items-center login-form__heading">
          <div class="col" />

          <!-- mymobility Wordmark -->
          <div class="col-auto mymo-wordmark">
            <Svg :vector-paths="mymobilityWordmark.vectorPaths" :view-box="mymobilityWordmark.viewBox" />
          </div>

          <!-- Question Circle Button -->
          <div class="col question">
            <zbm-skeleton-loader dark :loading="isLoading" size="30px">
              <zbm-btn-icon @click="showloginInfoModal = true" icon="fa-light fa-question-circle" size="30px"
                text-color="content-grd-bg-content" data-testid="questionButton" />
            </zbm-skeleton-loader>

            <!-- Question Circle Dialog -->
            <zbm-dialog v-model="showloginInfoModal" data-testid="loginHelpModal">

              <!-- Header -->
              <div class="row q-dialog__header">
                <div class="col-auto">

                  <!-- Question Circle Icon -->
                  <q-icon color="content-assets-hero-def" name="fa-solid fa-circle-question" size="42px" />
                </div>
              </div>

              <!-- Body -->
              <div class="row q-dialog__body">
                <div class="col">
                  <h3 class="text-weight-semibold q-mb-lg">{{ $t('log_in_help') }}</h3>
                  <p>
                    {{ $t('login_auth_help_ios') }}
                    <a href="https://support.zbmymobilitysolutions.com/start/" target="_blank" data-testid="supportSiteLink">
                      {{ $t('login_login_help_visit_support_website_ios') }}
                    </a>
                  </p>
                </div>
              </div>

              <!-- Footer (CTA button(s)) -->
              <div class="row q-dialog__footer">

                <!-- Okay Button -->
                <div class="col-auto">
                  <zbm-btn v-close-popup :color="$zb.enums.ButtonStdColorEnum.accentText"
                    :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_okay')" data-testid="loginHelpOkayButton"/>
                </div>
              </div>
            </zbm-dialog>
          </div>
        </div>

        <!-- Log In Form -->
        <q-form @submit.prevent="onSubmitSave" @validation-error="validationError = true" ref="loginForm">

          <!-- Username -->
          <div class="row justify-center mb-40">
            <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" dark height="40px" :loading="isLoading">
              <zbm-input-with-validation v-model="loginModel.username" dark :placeholder="$t('activate_username')"
                :rules="[$zb.validators.required()]" lazyRules="ondemand"/>
            </zbm-skeleton-loader>
          </div>

          <!-- Password -->
          <div class="row justify-center mb-40">
            <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" dark height="40px" :loading="isLoading">
              <zbm-password-input-with-validation v-model="loginModel.password" dark :placeholder="$t('password')"
                :rules="[$zb.validators.required()]" lazyRules="ondemand"/>
            </zbm-skeleton-loader>
          </div>

          <!-- Country Selector -->
          <div class="row flex-center country">

            <!-- Label -->
            <div class="col-auto font-size-20 text-content-grd-bg-content text-no-wrap">
              <zbm-skeleton-loader dark height="30px" :loading="isLoading" width="107px">
                {{ $t('provider_select_country_button') }}
              </zbm-skeleton-loader>
            </div>
            <div class="flex-br" />

            <!-- Select -->
            <zbm-skeleton-loader :class="$zb.enums.FormElWidthEnum.qFormEl300" dark height="40px" :loading="isLoading">
              <zbm-select-with-validation v-model="loginModel.countryCode" dark
                :name="$t('provider_select_country_button')" option-label="text" :options="countryList.countryOptions"
                option-value="value" :rules="[$zb.validators.required()]" />
            </zbm-skeleton-loader>
          </div>

          <!-- Log In Button -->
          <div class="row justify-center">
            <zbm-skeleton-loader :class="$zb.enums.ButtonWidthEnum.w300" dark height="44px" :loading="isLoading">
              <zbm-btn class="glow--white" :color="$zb.enums.ButtonStdColorEnum.darkTeal"
                :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('log_in')" :type="$zb.enums.ButtonTypeEnum.submit"
                :width="$zb.enums.ButtonWidthEnum.w300" data-testid="loginButton"/>
            </zbm-skeleton-loader>
          </div>
        </q-form>

        <!-- Forgot Username/Password -->
        <p v-if="isLoading" class="text-center login-form__forgot">
          <zbm-skeleton-loader dark height="24px" :loading="true" width="235px" />
        </p>
        <p v-else class="text-content-grd-bg-content text-center login-form__forgot"
          v-html="forgotUsernameOrPasswordRawHtml" />

        <!-- Activate Account -->
        <p class="text-content-grd-bg-content text-center login-form__activate">
          <zbm-skeleton-loader dark height="52px" :loading="isLoading" width="139px">
            {{ $t('log_in_first') }}<br />
            <router-link :to="{ name: 'Activate' }" data-testid="activateAccount">{{ $t('activate_account_sentence_case') }}</router-link>
          </zbm-skeleton-loader>
        </p>
      </Box>
    </div>
  </q-page>
</template>

<style scoped lang="scss">
@use "sass:math";

.login-form {
  // .q-page

  // Heading
  &__heading {
    // .row
    margin-block-end: 118px;

    // mymobility Wordmark
    .mymo-wordmark {
      // [class*="col"]
      line-height: 0;
      color: map-get($co-brand-clrs, content-grd-bg-content);

      svg {
        width: 100%;
        max-width: 240px;
        height: auto;
      }
    }

    // Question Circle Button
    .question {
      // [class*="col"]
      text-align: end;
    }
  }

  // Form
  .q-form {
    // <form>
    margin-block-end: 26px;

    // Country Selector
    .country {
      // .row
      margin-block-end: 54px;
    }
  }

  // Forgot Username/Password
  &__forgot {
    // <p>
    margin-block-end: 186px;
  }

  // Activate
  &__activate {
    // <p>
    $login-form-activate-font-size: map-get($font-sizes, '18');

    font-size: $login-form-activate-font-size;
    line-height: math.div(26px, $login-form-activate-font-size);
  }

  @media (max-width: 479px) {

    // Heading
    &__heading {
      // .row

      >[class*="col"]:first-child {
        display: none;
      }

      // mymobility Wordmark
      .mymo-wordmark {
        // [class*="col"]
        flex: 10000 1 0;
      }

      // Question Circle Button
      .question {
        // [class*="col"]
        flex: 0 0 auto
      }
    }
  }

  @media (min-width: 600px) {

    // Form
    .q-form {

      // Country Selector
      .country {

        .flex-br {
          display: none;
        }

        [class*=col]:first-child {
          padding-inline-end: 10px;
        }
      }
    }
  }
}
</style>
