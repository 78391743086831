import axios from 'src/api/axiosConfig'
import type { ApiResponse } from 'src/types/apiTypes'
import type { LoginRequest } from 'src/types/webContracts'
import type { AxiosResponse } from 'axios'
import { ApiRoutes } from '../ApiRoutes'

export async function postLogin(model: LoginRequest): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Login.LoginPost, model)
  return response;
}

export async function logout(): Promise<AxiosResponse<ApiResponse<boolean>>> {
  const response = await axios.post(ApiRoutes.Login.LogOutPost)
  return response;
}

export async function getMedStratSigningUrl(): Promise<AxiosResponse<ApiResponse<string>>> {
  const response = await axios.get(ApiRoutes.Login.GetMedStratSigningUrl)
  return response;
}