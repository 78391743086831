<script lang="ts" setup>
  import { ref, onMounted, onBeforeUnmount, defineProps, defineEmits } from 'vue';
  import { getMedStratSigningUrl } from 'src/api/endpoints/loginWeb';
  import FullViewLayout from 'src/components/layouts/FullViewLayout.vue';

  // state
  const eventName = ref<string>('Login');  // Default event name
  const srcUri = ref('');

  // local variables
  const iframeElementRef = ref<HTMLIFrameElement | null>(null);
  const pageTitle = 'MedStrat Images';
  const debugUri = ref('');
  const incomingMessage = ref('');
  const isDebug = false;

  onMounted(async () => {
    await getSingedUri();
    window.addEventListener('message', handleIncommingMessage);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('message', handleIncommingMessage);
  });

  function handleIncommingMessage(event: MessageEvent) {
    if (event.origin !== new URL(srcUri.value).origin) {
      console.warn('Message from unauthorized origin:', event.origin);
      return;
    }
    incomingMessage.value = event.data.message;
  }

  async function getSingedUri() {
    const response = await getMedStratSigningUrl();
    srcUri.value = 'https://examples.com';
//    srcUri.value = debugUri.value = response.data.result;
  }

  function postMessage() {
    srcUri.value = new URL(debugUri.value).origin;
    if (iframeElementRef.value?.contentWindow) {
      iframeElementRef.value.contentWindow.postMessage({ message: eventName.value }, debugUri.value);
    }
  }

</script>

<template>
  <FullViewLayout withHeader>
    <iframe ref="iframeElementRef"
            sandbox="allow-same-origin allow-scripts"
            :src="srcUri"
            :style="{ width: '100%', height: isDebug ? '75%' : '100%', border: 'none'}">
    </iframe>

    <q-card v-if="isDebug">
      <q-card-section class="column">
        <q-label>Debug section (to be removed )</q-label>

        <div class="row items-center">
          <q-label for="message">Message to send:</q-label>
          <q-input id="message" v-model="eventName" type="text" placeholder="Enter message" />
        </div>

        <div class="row items-center">
          <q-label for="debug-uri">Test URI:</q-label>
          <q-input id="debug-uri" v-model="debugUri" type="text" placeholder="https://example.com" class="col-6" />
          <button @click="postMessage">Post Message</button>
        </div>

        <div class="row items-center">
          <q-label>Incoming message:</q-label>
          {{incomingMessage}}
        </div>

      </q-card-section>
    </q-card>

  </FullViewLayout>
</template>
