<script setup lang="ts">
import { ref, computed } from 'vue';
import { useMessagesStore, useLocalizationStore, useSessionStore } from 'src/store';
import { type MessageModel } from 'src/types/interfaces';
import { toUserFacingDate } from 'src/utils/dateUtil';
import {formatName} from '@/utils/interfaceUtils';
import Avatar from '@/components/Avatar.vue';

const props = defineProps<{
  message: MessageModel;
  isClinicianMsg: boolean;
}>();

const messagesModule = useMessagesStore();
const sessionModule = useSessionStore();
const localizationModule = useLocalizationStore();

const responseBody = ref('');

const showContentModal = ref(false);

const createdDate = computed(() => toUserFacingDate(props.message.createdDate, true, localizationModule.locale));

const avatar = computed(() => {
  let avatar: string | undefined | null = null;
  if (props.isClinicianMsg) {
    if (!messagesModule.careTeam) return null;
    avatar = messagesModule.getAvatarforClinicianUserId(props.message.fromId.toString());
  } else {
    avatar = sessionModule.user?.avatarBase64;
  }

  if (!avatar) return null;
  return `data:image/png;base64,${avatar}`;
});

const formatedMessage = computed(() => {
  const message = clearAnchorTagGetUrl(props.message.message);
  return [message];
});

const name = computed(() => {
  return formatName({firstName: props.message.fromFirstName, lastName: props.message.fromLastName});
});

function clearAnchorTagGetUrl(message: string): string {
  const anchorOpen = /<a.*?>/g,
    anchorClose = /<\/a>/g,
    url = /(http:\/\/|https:\/\/)([\S]+)/g,
    str = message.replace(anchorOpen, '').replace(anchorClose, '');

  const result: string = str.replace(url, formatLink);
  return result;
}

function formatLink(match: string) {
  if (/[.!?]$/.test(match)) {
    const urlWithMark = /(http:\/\/|https:\/\/)([\S]+)([.!?]$)/g;
    return match.replace(urlWithMark, '<a href="$1$2" target="_blank">$1$2</a>$3');
  }
  return '<a href="' + match + '" target="_blank">' + match + '</a>';
}

async function clickHandler() {
  responseBody.value = '/Asset/GetMessageAttachment?messageId=' + props.message.messageId + '&dataId=' + props.message.dataId;
  showContentModal.value = true;
}
</script>

<template>
  <q-chat-message :name="name + ', ' + createdDate"
                  :sent="isClinicianMsg">

    <!-- Avatar -->
    <template #avatar>
      <Avatar :avatar-url="avatar !== null ? avatar : undefined"
              color="secondary"
              :display-name="name"
              :has-avatar-image="avatar !== null"
              :icon-border="true"
              :icon-font-size="isClinicianMsg ? '24px' : '34px'"
              :icon-name="'fa-solid ' + (isClinicianMsg ? 'fa-user-md' : 'fa-user-alt')"
              :icon-top-margin="isClinicianMsg ? '' : '5px'"
              size="42px" />
    </template>

    <!-- Text -->
    <p v-if="message.message != ''"
       v-html="formatedMessage" />

    <!-- Image -->
    <div v-if="message.mediaContentType?.match('image')"
         @click="clickHandler()"
         class="media"
         :data-url="message.mediaUrl" >
      <q-icon name="fa-solid fa-image"
            size="24px" />
    </div>

    <!-- Video -->
    <div v-if="message.mediaContentType?.match('video')"
         @click="clickHandler()"
         class="media"
         data-url="URL">
      <q-icon name="fa-solid fa-video"
              size="24px" />
    </div>
  </q-chat-message>

  <zbm-dialog v-if="message.mediaUrl"
              v-model="showContentModal"
              :close-button="true">

      <!-- .jpg/.jpeg Image -->
      <img v-if="message.mediaContentType == 'image/jpeg'"
           :src="responseBody" />

      <!-- .png Image -->
      <img v-if="message.mediaContentType == 'image/png'"
           :src="responseBody" />

      <!-- Video -->
      <video v-if="message.mediaContentType == 'video/mp4'"
             controls
             controlslist="nodownload nofullscreen"
             disablepictureinpicture>
        <source :src="responseBody"
                type="video/mp4" />
      </video>
  </zbm-dialog>
</template>

<style lang="scss" scoped>
.q-dialog {

  // .jpg/.jpeg/.png Image & Video
  img,
  video {
    max-width: 700px;
    width: 100%;
    max-height: 700px;
  }

  // .jpg/.jpeg/.png Image
  img {
    height: auto;
  }
}
</style>
