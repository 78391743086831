<script setup lang="ts">
import {
  defineProps,
} from 'vue';

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  disable: {
    type: Boolean
  },
  icon: {
    type: String,
    required: true
  },
  iconSize: {
    type: String,
    default: 'sm'
  },
  to: {
    type: String
  },
  caption: {
    type: String
  },
  hideArrow: {
    type: Boolean,
    default: false
  },
  arrowColor: {
    type: String,
    default: 'dark-teal'
  }
});

</script>

<template>
  <q-item clickable class="--nav" :to="to" :disable="disable">
    <q-item-section v-if="icon" avatar>
      <slot name="icon">
        <q-icon :size="iconSize" :name="icon" />
      </slot>
    </q-item-section>

    <q-item-section>
      <q-item-label>{{ label }}</q-item-label>
      <q-item-label v-if="caption" caption>{{ caption }}</q-item-label>
    </q-item-section>

    <q-item-section avatar v-if="!hideArrow">
      <q-icon name="fa-regular fa-arrow-right-long" size="2rem" :color="arrowColor"></q-icon>
    </q-item-section>
  </q-item>
</template>
