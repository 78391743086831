<script setup lang="ts">
import {
  defineProps,
  type PropType
} from 'vue';

const props = defineProps({
  class: {
    type: String
  },
  click: {
    type: Function
  },
  disable: {
    type: Boolean,
    default: false
  },
  label: {
    type: String
  },
  loading: {
    type: Boolean,
    default: false
  },
  locked: {
    type: Boolean
  },
  to: {
    type: Object as PropType<string | object>
  },
  dataTestId: {
    type: String
  }
});
</script>

<template>
  <div class="priority">

    <!-- Title -->
    <h4>
      <zbm-skeleton-loader height="26px"
                           :loading="props.loading"
                           width="100%">
        {{ props.label }}
      </zbm-skeleton-loader>
    </h4>

    <!-- Today's Tasks Priority Button -->
    <zbm-skeleton-loader height="62px"
                         :loading="props.loading"
                         width="100%">
      <zbm-btn @click="props.click"
               :class="props.class"
               :disable="props.disable"
               :rounded="false"
               :to="props.to"
               :data-testid="props.dataTestId">

        <!-- Icon -->
        <div class="q-btn__icon">
          <slot name="icon" />
        </div>

        <!-- Label -->
        <div class="q-btn__label">
          <slot />
        </div>

        <!-- Status -->
        <div>
          <q-icon :color="props.locked ? 'body' : 'primitives-brand-100'"
                  :name="'fa-solid ' + (props.locked ? 'fa-lock' : 'fa-chevron-right')"
                  size="24px" />
        </div>
      </zbm-btn>
    </zbm-skeleton-loader>
  </div>
</template>

<style lang="scss" scoped>
.priority { // <div>

  h4 {
    margin-block-end: 17px;
  }

  .q-btn {
    width: 100%;
    min-height: 62px;
    background-color: $white;
    font-weight: map-get($text-weights, regular);
    font-size: $body-font-size;
    line-height: 1;
    color: map-get($co-brand-clrs, primitives-brand-100);
    flex-wrap: nowrap;
    padding-inline: 25px 18px;
    border: 2px solid $todays-tasks-brdr-clr;
    border-radius: $generic-border-radius;

    // Icon
    &__icon {
      line-height: 0;

      :deep(svg) {
        width: auto;
        height: 26px;
      }
    }

    // Label
    &__label {
      min-width: 0;
      width: 1%;
      text-align: start;
      flex: 1 1 auto;
      padding-inline: 20px 18px
    }
  }

  // Video Visit Coming Up
  &:deep(.coming-up) { // .q-btn

    // Label
    .q-btn__label span:first-child {
      font-weight: map-get($text-weights, bold);
      padding-inline-end: 8px;
      border-inline-end: 1px solid $banner-bkgd-clr;
      margin-inline-end: 13px;
    }
  }
}
</style>
