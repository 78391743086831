<script setup lang="ts">
import {
  computed,
  defineModel,
  watch
} from 'vue';
import { useGeneralPurposeModalStore } from '@/store';
import { GeneralPurposeVariant } from '@/constants/enums';
import { type GeneralPurposeModalModel } from '@/types/interfaces';

const generalPurposeModalStore = useGeneralPurposeModalStore()

// V-Model
const showDialog = defineModel({
  type: Boolean,
  default: false
});

// Computed
const modal = computed(() => generalPurposeModalStore.modal),
  icon = computed(() => {
    const variant = modal.value?.variant;

    let icon = '';

    switch (variant) {
      case GeneralPurposeVariant.Decision:
        icon = 'exclamation';
        break;
      case GeneralPurposeVariant.Information:
        icon = 'question';
        break;
      case GeneralPurposeVariant.Success:
        icon = 'check';
        break;
    }

    return icon;
  }),
  title = computed(() => modal.value?.title),
  template = computed(() => modal.value?.template),
  onCloseCallback = computed(() => modal.value?.onClose || (() => { }));

// Watch for changes in modal
watch(() => modal.value, (newVal: GeneralPurposeModalModel, oldVal: GeneralPurposeModalModel) => {
  showDialog.value = !!newVal;
});

// Methods
const onOkayClicked = () => {
  showDialog.value = false;
  onCloseCallback.value();
  generalPurposeModalStore.setModal(null);
};
</script>

<template>
  <zbm-dialog v-model="showDialog" data-testid="modalDialog">

    <!-- Header -->
    <div class="row q-dialog__header">
      <div class="col-auto">

        <!-- Icon -->
        <q-icon color="dark-teal" :name="`fa-solid fa-circle-${icon}`" size="42px" />
      </div>
    </div>

    <!-- Body -->
    <div class="row q-dialog__body">
      <div class="col">
        <h3 v-if="title" class="text-weight-semibold q-mb-lg">
          {{ title }}
        </h3>
        <p v-html="template" />
      </div>
    </div>

    <!-- Footer (CTA button(s)) -->
    <div class="row q-dialog__footer">

      <!-- Okay Button -->
      <div class="col-auto">
        <zbm-btn @click="onOkayClicked" :color="$zb.enums.ButtonStdColorEnum.accent"
          :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_okay')" data-testid="okayButton" />
      </div>
    </div>
  </zbm-dialog>
</template>
