<script lang="ts" setup>
import {
  computed,
  onMounted,
  ref
} from 'vue';
import axios from 'axios';
import { type EpisodeOfCareInfo } from 'src/types/webContracts';
import { useSessionStore } from 'src/store';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { getPatientProcedures, setSelectedProcedure } from 'src/api/endpoints/userWeb';
import { logout } from 'src/api/endpoints/loginWeb';
import { factorProcedures } from 'src/utils/procedureUtils';
import Box from 'src/components/BoxContainer.vue';
import EocNavBtn from 'src/components/EocNavBtn.vue';
import { i18n } from '@/plugins/i18n';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';
import { userLogout } from '@/services/userService';

const $q = useQuasar()

// Component options
const isLoading = ref(true);
const hasOneProc = ref(true);
const procedures = ref([] as EpisodeOfCareInfo[]);
const hasDisabledProcedure = ref(false);
const showDisabledProcedure = ref(false);
const router = useRouter();

// Stores
const sessionStore = useSessionStore();

// Computed properties
const firstName = computed(() => sessionStore.user?.firstName);

// Methods
const onViewAllClicked = () => {
  showDisabledProcedure.value = !showDisabledProcedure.value;
};

onMounted(async () => {
  document.querySelectorAll('html')[0].scrollTop = 0;

  sessionStore.setIsHeaderVisible(false);
  sessionStore.setIsFooterVisible(false);

  // Get Patient Procedures
  const getPatientProcRsp = await handleApiRequest(() => getPatientProcedures(), null, $q);

  if (getPatientProcRsp?.result) {
    let episodesOfCare = getPatientProcRsp.result.episodesOfCare;
    factorProcedures(episodesOfCare);
    sessionStore.loadProcedures(episodesOfCare);
    procedures.value = episodesOfCare;
    hasDisabledProcedure.value = episodesOfCare.find((x) => x.status === 3) !== undefined;

    if (procedures.value.length === 1) {
      const id = procedures.value[0].eocId;

      try {
        const selectedProcedure = await setSelectedProcedure(id);

        if (selectedProcedure) {
          sessionStore.setSelectedEocId(id);
          router.push({ name: 'Interrupts' });
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          $q.notify({ type: 'error', message: i18n.global.t(error.response?.data?.errorMessageKey).toString() })
        }
      }
    } else {
      hasOneProc.value = false;
    }

    sessionStore.setIsHeaderVisible(true);
    sessionStore.setIsFooterVisible(true);
  } else {
    await userLogout($q);
  }

  isLoading.value = false;
});
</script>

<template>
  <q-page class="not-auth-header select-care-plan" padding>
    <div v-if="!hasOneProc"
         class="row bs-gutter justify-center">

      <!-- Box -->
      <Box :box-bkgd-color="$zb.enums.BoxBackgroundColorEnum.linearGradient"
        :box-brdr-radius="$zb.enums.BoxBorderRadiusEnum.lg" :box-drop-shadow="$zb.enums.BoxDropShadowEnum.xl"
        :box-padding="$zb.enums.BoxPaddingEnum.p505065" :box-width="$zb.enums.BoxWidthEnum.md" class="col-auto">

        <!-- Heading -->
        <div class="row heading">
          <div class="col text-center mb-50">
            <h1 class="text-content-grd-bg-content q-mb-lg">
              <zbm-skeleton-loader height="34px" :loading="isLoading" width="100%">
                {{ $t('vue3_eoc_selection_header', [firstName]) }}
              </zbm-skeleton-loader>
            </h1>
            <p class="text-content-grd-bg-content font-size-20">
              <zbm-skeleton-loader height="30px" :loading="isLoading" width="100%">
                {{ $t('eoc_selection_subheader') }}
              </zbm-skeleton-loader>
            </p>
          </div>
        </div>

        <!-- Procedures -->
        <div class="procedures">
          <zbm-skeleton-loader class="column" height="94px" :loading="isLoading" width="100%">
            <EocNavBtn v-for="procedure in procedures" :key="procedure.eocId" :procedure="procedure"
              :showDisabledProcs="showDisabledProcedure" />
          </zbm-skeleton-loader>
        </div>

        <!-- View All -->
        <div v-if="hasDisabledProcedure" v-show="!showDisabledProcedure" class="row justify-center pt-30">
          <div class="col-auto">
            <zbm-skeleton-loader height="19px" :loading="isLoading" width="78px">
              <zbm-btn-link @click="onViewAllClicked" class="font-size-14" color="content-grd-bg-content"
                icon-right="fa-solid fa-angle-down" :label="$t('eoc_view_all')" :no-caps="false" />
            </zbm-skeleton-loader>
          </div>
        </div>
      </Box>
    </div>
  </q-page>
</template>

<style scoped lang="scss">
.select-care-plan {

  // Procedures
  .procedures {
    // .row
    margin-inline: -50px;
  }
}
</style>
