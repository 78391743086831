<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useSessionStore } from '@/store/sessionStore.js';
import BoxContainer from '@/components/BoxContainer.vue'
import Svg from '@/components/Svg.vue';
import {mymobilityWordmark} from '@/assets'

const sessionStore = useSessionStore();
const carousel = ref(null);
const slide = ref('1');

onMounted(() => {
  document.querySelectorAll('html')[0].scrollTop = 0;
  sessionStore.setIsHeaderVisible(false);
});

const currentSlide = computed(() => {
  return carousel.value?.modelValue != 5;
});

function gotoSlide(index: number) {
  carousel.value.goTo(index.toString());
}
</script>

<template>
  <q-page class="overview" padding>
    <div class="row bs-gutter justify-center">

      <!-- Box -->
      <BoxContainer :box-brdr-radius="$zb.enums.BoxBorderRadiusEnum.lg"
                    :box-drop-shadow="$zb.enums.BoxDropShadowEnum.xl"
                    :box-padding="$zb.enums.BoxPaddingEnum.p505065"
                    :box-width="$zb.enums.BoxWidthEnum.md"
                    class="col-auto relative-position">

        <!-- Linear Gradient Background -->
        <div class="linear-gradient-bkgd" />
        <div class="relative-position">

          <!-- mymobility Wordmark -->
          <div class="row justify-center mymo-wordmark">
            <div class="col-auto">
              <Svg :view-box="mymobilityWordmark.viewBox"
                   :vector-paths="mymobilityWordmark.vectorPaths" />
            </div>
          </div>

          <!-- Content -->
          <div class="row content">
            <div class="col">
              <p>{{ $t('activate_onboard_text') }}</p>
            </div>
          </div>

          <!-- Introduction to PWE Slides -->
          <div class="row intro-slides">
            <div class="col">
              <q-carousel v-model="slide"
                          arrows
                          control-color="dark-teal"
                          control-text-color="dark-teal"
                          infinite
                          navigation
                          navigation-position="bottom"
                          next-icon="fa-light fa-chevron-right"
                          padding
                          prev-icon="fa-light fa-chevron-left"
                          ref="carousel">

                <!-- Navigation Icon -->
                <template #navigation-icon="{ active, onClick }">
                  <zbm-btn-icon @click="onClick"
                                :class="active ? '' : 'q-carousel__navigation-icon--inactive'"
                                icon="fa-solid fa-circle"
                                size="12px"
                                :text-color="$zb.enums.ButtonStdColorEnum.darkTeal" />
                </template>

                <!-- Slide 1 -->
                <q-carousel-slide name="1">
                  <div class="icon-bkgd">
                    <q-icon name="fa-solid fa-tasks" />
                  </div>
                  <p>{{ $t('activate_onboard_task') }}</p>
                </q-carousel-slide>

                <!-- Slide 2 -->
                <q-carousel-slide name="2">
                  <div class="icon-bkgd">
                    <q-icon name="fa-solid fa-graduation-cap" />
                  </div>
                  <p>{{ $t('activate_onboard_education') }}</p>
                </q-carousel-slide>

                <!-- Slide 3 -->
                <q-carousel-slide name="3">
                  <div class="icon-bkgd">
                    <q-icon name="fa-solid fa-comments"  />
                  </div>
                  <p>{{ $t('activate_onboard_message') }}</p>
                </q-carousel-slide>

                <!-- Slide 4 -->
                <q-carousel-slide name="4">
                  <div class="icon-bkgd">
                    <q-icon name="fa-solid fa-chart-bar"  />
                  </div>
                  <p>{{ $t('activate_onboard_stats') }}</p>
                </q-carousel-slide>

                <!-- Slide 5 -->
                <q-carousel-slide name="5">
                    <img src="/img/mymobility-icon-color.svg" alt="mymobility" />
                    <h3>{{ $t('activate_onboard_letsgo_title') }}</h3>
                    <p>{{ $t('activate_onboard_letsgo_content') }}</p>
                </q-carousel-slide>
              </q-carousel>
            </div>
          </div>

          <!-- Defeatured Text -->
          <div class="row flex-center defeatured">
            <div v-if="currentSlide" class="col">
              <p>{{ $t('activate_onboard_disclaimer_text') }}</p>
            </div>
          </div>

          <!-- Button(s) -->
          <div class="row buttons">

            <!-- Skip Button -->
            <div v-if="currentSlide"
                 class="col">
              <zbm-btn @click="gotoSlide(5)"
                       :color="$zb.enums.ButtonStdColorEnum.accentText"
                       :height="$zb.enums.ButtonHeightEnum.lg"
                       :label="$t('activate_skip')"
                       :width="$zb.enums.ButtonWidthEnum.w270" />
            </div>

            <!-- Continue -->
            <div v-else
                 class="col">

              <!-- Button -->
              <zbm-btn :color="$zb.enums.ButtonStdColorEnum.accent"
                       class="mb-10"
                       :height="$zb.enums.ButtonHeightEnum.lg"
                       :label="$t('activate_continue_text')"
                       to="/Activate/Start"
                       :width="$zb.enums.ButtonWidthEnum.w270" />

              <!-- "Learn More" Text -->
              <p>
                {{ $t('activate_onboard_footer') }}<br />
                <a href="https://www.zimmerbiomet.com/patients-caregivers/wearable-technology.html"
                   target="_blank">
                  {{ $t('activate_onboard_footer_email_android') }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </BoxContainer>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
@use 'sass:math';

.overview {

  // Linear Gradient Background
  .linear-gradient-bkgd { // <div>
    $width: 3170px;
    $height: 1504px;

    position: absolute;
    top: -($height - 400px);
    left: 50%;
    transform: translateX(-50%);
    width: $width;
    height: $height;
    background-image: $linear-gradient-sm;
    border-radius: 50%;
  }

  // mymobility Wordmark
  .mymo-wordmark { // .row
    color: map-get($co-brand-clrs, content-grd-bg-content);
    margin-block-end: 52px;

    > [class*="col"] {
      line-height: 0;
    }

    svg {
      width: 100%;
      max-width: 240px;
      height: auto;
    }
  }

  // Content
  .content { // .row

    p {
      $overview-content-font-size: map-get($font-sizes, '22');

      font-size: $overview-content-font-size;
      line-height: math.div(30px, $overview-content-font-size);
      color: map-get($co-brand-clrs, content-grd-bg-content);
      text-align: center;
    }
  }

  // Introduction to PWE Slides
  .intro-slides { // .row

    // Slide
    :deep(.q-carousel__slide) {
      max-width: 420px;
      min-height: 225px;
      background-color: white;
      text-align: center;
      padding: 25px 35px 35px;
      border-radius: $generic-border-radius;
      box-shadow: $box-shadow-xl;

      .icon-bkgd {
        $slides-icon-bkgd-size: 92px;

        display: flex;
        width: $slides-icon-bkgd-size;
        height: $slides-icon-bkgd-size;
        background-color: map-get($co-brand-clrs, primitives-foundation-100);
        font-size: 48px;
        line-height: 0;
        color: $white;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 0 0 14px -6px map-get($co-brand-clrs, primitives-foundation-100);
        margin-inline: auto;
        margin-block-end: 21px;
      }

      p {
        $slides-p-font-size: map-get($font-sizes, '20');

        font-size: $slides-p-font-size;
        line-height: math.div(map-get($line-heights, '26'), $slides-p-font-size);
      }

      img {
        width: 68px;
        height: auto;
      }

      h3 {
        margin-block: -6px 21px;
      }
    }

    // Arrow
    :deep(.q-carousel__arrow) {
      top: 64px;
    }
  }

  // Defeatured Text
  .defeatured { // .row
    height: 150px;
    text-align: center;
  }

  // Button(s)
  .buttons { // .row

    > [class*="col"] {
      text-align: center;
    }

    .q-btn {
      margin: 0 auto;
    }

    p {
      font-size: map-get($font-sizes, '14');
      line-height: $line-height-lg;
    }
  }

  @media (min-width: 575px) {

    // Content
    .content [class*="col"] {
      padding-inline: 25px;
    }
  }

  @media (max-width: 585px) {

    // Introduction to PWE Slides
    .intro-slides { // .row
      margin-inline: -50px;

      // QCarousel Slides Container
      :deep(.q-carousel__slides-container) {
        padding-inline: 0;
      }

      // QCarousel Arrow(s)
      :deep(.q-carousel__arrow) {
        display: none;
      }
    }
  }

  @media (min-width: 640px) {

    // Content
    .content [class*="col"] {
      padding-inline: 90px;
    }
  }
}
</style>
