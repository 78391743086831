<script setup lang="ts">
import { ref, computed, watch, onUpdated, onActivated, nextTick } from 'vue';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { ClientFeatureType, GeneralPurposeVariant } from 'src/constants/enums';
import { useDashboardStore, useGeneralPurposeModalStore, useMessagesStore, useSessionStore } from 'src/store';
import { reformatString } from 'src/utils/stringUtils';
import MessageDisplay from 'src/pages/DashboardMessages/components/MessageDisplay.vue';
import { getMessages, markAllMessagesAsRead, sendMessage } from 'src/api/endpoints/messagesWeb';
import DisclaimerModal from 'src/components/DisclaimerModal.vue';
import TheYourCareTeam from 'src/pages/DashboardMessages/components/TheYourCareTeam.vue'
import { i18n } from '@/plugins/i18n';
import type { GeneralPurposeModalModel } from '@/types/interfaces';
import { useQuasar, QScrollArea } from 'quasar';
import { useRoute } from 'vue-router';

const $i18nt = i18n.global.t;
const route = useRoute();
const newMessage = ref('');
const showCareTeamModal = ref(false);
const showMessagesDisclaimer = ref(false);

const messagesModule = useMessagesStore();
const sessionModule = useSessionStore();
const dashboardStore = useDashboardStore();
const generalPurposeModalStore = useGeneralPurposeModalStore();
const $q = useQuasar()

const currentUserId = computed(() => sessionModule.user?.userId);
const eocId = computed(() => sessionModule.user?.selectedEocId);
const isLoading = computed(() => messagesModule.isLoading);
const messages = computed(() => messagesModule.messages);
const todaysDate = computed(() => dashboardStore.todaysDate);
const messagesDisabled = computed(() => !sessionModule.user?.clientFeatures.includes(ClientFeatureType.Messaging));
const hideMessagesInput = computed(() => messagesDisabled.value || !messages.value || messages.value.length <= 0);
const noMessages = computed(() => reformatString(messagesDisabled.value ? $i18nt('messages_disabled') : $i18nt('messages_disabled_none')));
const threadScrollArea = ref(null as QScrollArea | null);

const outOfOfficeMessageDescription = computed(() => messagesModule.outOfOfficeMessageDescription);
const outOfOfficeMessageTitle = computed(() => messagesModule.outOfOfficeMessageTitle);
const oooMessage = ref();
const oooMsgHeight = ref(0);

const scrollToThreadBottomPending = ref(true);

const setOooMsgMore = computed(() => {
  return oooMsgHeight.value > 48;
});

watch(hideMessagesInput, async (newVal) => {
  if (!newVal) {
    await nextTick(() => {
      scrollToThreadBottomPending.value = true;
      if (route.name === 'Dashboard.Messages') {
        scrollToThreadBottom();
      }
    });
  }
});

onActivated(async () => {
  if (scrollToThreadBottomPending.value) {
    setOutOfOfficeModal();
    await nextTick(() => {
      scrollToThreadBottom();
    });
  }
});

onUpdated(() => {
  oooMsgHeight.value = oooMessage.value?.clientHeight;
});

async function reloadMessages() {
  const messagesResponse = await handleApiRequest(() => getMessages(), null, $q);
  if (messagesResponse) {
    messagesModule.loadMessages(messagesResponse.result);
  }
}

async function markMessagesAsRead() {
  if (messagesModule.unreadMessagesCount == 0) return;
  const result = await handleApiRequest(() => markAllMessagesAsRead(), null, $q);
  if (result?.result) {
    messagesModule.clearUnreadMessagesCount();
  }
}

async function onSendClick() {
  if (newMessage.value) {
    $q.loading.show()
    const createMessageRequestModel = {
      surgeonId: dashboardStore.patient?.surgeonId || 0,
      careTeamId: dashboardStore.patient?.careTeamId || 0,
      dataId: '0',
      message: newMessage.value,
      eocId: eocId.value
    };
    const messageResult = await handleApiRequest(() => sendMessage(createMessageRequestModel), null, $q);
    if (messageResult?.result) {
      await reloadMessages();
      newMessage.value = '';
    }
    $q.loading.hide()
  }
  await nextTick(() => {
    scrollToThreadBottom(300);
  });
}

function setOooModal() {
  const oooModal = {
    title: outOfOfficeMessageTitle.value,
    template: outOfOfficeMessageDescription.value,
    variant: GeneralPurposeVariant.Information,
  } as GeneralPurposeModalModel;

  generalPurposeModalStore.setModal(oooModal);
}

function setOutOfOfficeModal() {
  if (!messagesModule.hasShownOooModal && messagesModule.outOfOfficeMessageDescription.length) {
    setOooModal();

    messagesModule.setHasShownOooModal();
  }
}

function triggerOooModal() {
  if (setOooMsgMore.value) {
    setOooModal();
  }
}

function scrollToThreadBottom(scrollDuration = 0) {
  const scrollTarget = threadScrollArea.value?.getScrollTarget();
  threadScrollArea.value?.setScrollPosition('vertical', scrollTarget?.scrollHeight ?? 0, scrollDuration);
  markMessagesAsRead();
  scrollToThreadBottomPending.value = false;
}
</script>

<template>

  <!-- Heading -->
  <div class="row bs-gutter items-center dash-msgs__heading">

    <!-- Care Team  -->
    <div class="col-auto team lt-md">

      <!-- Button -->
      <zbm-skeleton-loader height="26px" :loading="isLoading" width="33px">
        <zbm-btn-icon @click="showCareTeamModal = true" icon="fa-solid fa-users" size="26px" text-color="dark-teal" />
      </zbm-skeleton-loader>

      <!-- Dialog -->
      <zbm-dialog v-model="showCareTeamModal" :close-button="true">

        <!-- Body -->
        <div class="row q-dialog__body">
          <div class="col">
            <TheYourCareTeam />
          </div>
        </div>

        <!-- Footer (CTA button(s)) -->
        <div class="row q-dialog__footer">
          <div class="col-auto">
            <zbm-btn @click="showCareTeamModal = false" :color="$zb.enums.ButtonStdColorEnum.accentText"
              :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_okay')" />
          </div>
        </div>
      </zbm-dialog>
    </div>

    <!-- Title -->
    <div class="col title">
      <zbm-skeleton-loader height="34px" :loading="isLoading" width="100%">
        <h1>{{ $t('pwe_messages') }}</h1>
      </zbm-skeleton-loader>
    </div>

    <!-- Disclaimer -->
    <div class="col-auto disclaimer">

      <!-- Button -->
      <zbm-skeleton-loader height="26px" :loading="isLoading" width="26px">
        <zbm-btn-icon @click="showMessagesDisclaimer = true" icon="fa-light fa-circle-question" size="26px"
          text-color="dark-teal" />
      </zbm-skeleton-loader>

      <!-- Modal -->
      <DisclaimerModal v-model="showMessagesDisclaimer" disclaimer-id="messagesDisclaimer" opt-out
        :policy-type="$zb.enums.UserPolicyType.UserPolicyMessagingDisclaimer" :show-on-activate="true"
        title-key="pwe_pme_messages_header" />
    </div>
    <div class="flex-br" />

    <!-- Date -->
    <div class="col-auto date">
      <zbm-skeleton-loader height="24px" :loading="isLoading" width="100px">
        {{ todaysDate }}
      </zbm-skeleton-loader>
    </div>
  </div>

  <!-- Out of Office Message Banner -->
  <template v-if="!messagesDisabled && outOfOfficeMessageDescription">
    <zbm-skeleton-loader class="mb-30" height="107px" :loading="isLoading" width="100%">
      <q-banner @click="triggerOooModal" :class="['bg-accent', { 'cursor-pointer non-selectable': setOooMsgMore }]"
        inline-actions rounded>

        <!-- Icon -->
        <template #avatar>
          <q-icon name="fa-solid fa-info-circle" size="28px" />
        </template>

        <!-- Content -->
        <template #default>

          <!-- Title -->
          <p v-html="outOfOfficeMessageTitle" class="text-weight-bold mb-none" />

          <!-- More Ellipsis -->
          <q-icon v-if="setOooMsgMore" name="fa-regular fa-ellipsis" />

          <!-- Message -->
          <div class="message">
            <p v-html="outOfOfficeMessageDescription" ref="oooMessage" />
          </div>
        </template>

        <!-- Action(s) -->
        <template #action>
          <zbm-btn-icon v-if="setOooMsgMore" icon="fa-regular fa-angle-right" size="30px" />
        </template>
      </q-banner>
    </zbm-skeleton-loader>
  </template>

  <!-- Thread -->
  <div class="row mb-50 dash-msgs__thread" ref="frame">
    <div class="col">
      <zbm-skeleton-loader height="510px" :loading="isLoading" width="100%">

        <!-- No Messages -->
        <p v-if="hideMessagesInput" v-html="noMessages" class="text-italic" />

        <!-- Messages -->
        <q-scroll-area v-else ref="threadScrollArea">
          <MessageDisplay v-for="message in messages" :isClinicianMsg="message.fromId !== currentUserId"
            :key="message.messageId" :message="message" />
        </q-scroll-area>
      </zbm-skeleton-loader>
    </div>
  </div>

  <!-- Create New Message -->
  <div class="row flex-center dash-msgs__create">

    <!-- Input -->
    <div class="col">
      <zbm-skeleton-loader height="40px" :loading="isLoading" width="100%">
        <zbm-input-with-validation v-model="newMessage" :disable="messagesDisabled"
          :input-width="$zb.enums.FormElWidthEnum.qFormElFull" outlined
          :placeholder="$t('messages_messenger_placeholder_text_ios')" />
      </zbm-skeleton-loader>
    </div>
    <div class="flex-br mb-10" />

    <!-- Send Button -->
    <div class="col-auto">
      <zbm-skeleton-loader height="40px" :loading="isLoading" width="116px">
        <zbm-btn @click="onSendClick" :color="$zb.enums.ButtonStdColorEnum.accent"
          :disable="messagesDisabled || !newMessage" :height="$zb.enums.ButtonHeightEnum.md"
          :label="$t('messages_send')" />
      </zbm-skeleton-loader>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dash-msgs {

  // Heading
  &__heading {
    // .row
    margin-block-end: 25px;
  }

  // Thread
  &__thread {
    // .row

    .q-scrollarea {
      height: 510px;
      padding-inline-end: 20px;
    }
  }

  @media (max-width: 499px) {

    // Heading
    &__heading {
      // .row

      // Title
      .title {
        // [class*="col"]
        padding-inline: 0;
      }
    }
  }

  @media (min-width: 500px) {

    // Heading
    &__heading {
      // .row

      // Care Team
      .team {
        // [class*="col"]
        order: 1;
      }

      // Title
      .title {
        // [class*="col"]
        order: 2;
      }

      // Disclaimer
      .disclaimer {
        // [class*="col"]
        order: 4;
      }

      // Date
      .date {
        // [class*="col"]
        order: 3;
      }
    }

    // Create New Message
    &__create {
      // .row

      >[class*="col"] {

        &:first-child {
          padding-inline-end: 5px;
        }

        &:last-child {
          padding-inline-start: 5px;
        }
      }

      .flex-br {
        display: none;
      }
    }
  }

  @media (min-width: $breakpoint-md-min) {}
}

// Out of Office Message
.q-banner {
  padding: 15px 10px 20px 22px;

  :deep(a) {
    color: $pain-rate-ring-bkgd-clr;
    text-decoration: none;
  }

  // Avatar
  :deep(.q-banner__avatar) {
    align-self: flex-start;
  }

  // Content
  :deep(.q-banner__content) {
    position: relative;

    // More Ellipsis
    .q-icon {
      position: absolute;
      bottom: -1px;
      right: 0;
      background-image: linear-gradient(to right, rgba($accent, 0) 0%, rgba($accent, 1) 25%);
      padding-block-start: 9px;
      padding-inline-start: 20px;
    }

    .message {
      // <p>
      max-height: 48px;
      word-break: break-word;
      overflow: hidden;
    }

    // Action(s)
    &+.q-banner__actions.col-auto:not(:empty) {
      padding-inline-start: 15px;
    }
  }
}
</style>
