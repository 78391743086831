
import moment from 'moment';
import 'moment/dist/locale/ca';

import 'moment/dist/locale/ar';
import 'moment/dist/locale/ar-sa';

import 'moment/dist/locale/de';
import 'moment/dist/locale/de-at';
import 'moment/dist/locale/de-ch';

import 'moment/dist/locale/en-au';
import 'moment/dist/locale/en-ca';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/en-ie';
import 'moment/dist/locale/en-in';
import 'moment/dist/locale/en-nz';
import 'moment/dist/locale/en-sg';

import 'moment/dist/locale/es';
import 'moment/dist/locale/es-us';
import 'moment/dist/locale/es-mx';

import 'moment/dist/locale/fi';

import 'moment/dist/locale/fr';
import 'moment/dist/locale/fr-ca';
import 'moment/dist/locale/fr-ch';

import 'moment/dist/locale/hi';

import 'moment/dist/locale/it';
import 'moment/dist/locale/it-ch';

import 'moment/dist/locale/ja';

import 'moment/dist/locale/nl';
import 'moment/dist/locale/nl-be';

import 'moment/dist/locale/pt-br';

import 'moment/dist/locale/sv';

import 'moment/dist/locale/zh-cn';

export default moment;

export function registerSyntheticMomentCultures(currentLocale) {
  currentLocale = currentLocale?.toLowerCase();
  switch (currentLocale) {
    case 'en-ch': //this is a fake Swiss-English
      const countryData = moment.localeData('de-CH'); //just picking one
      const deCHConfig = moment.localeData('de-CH')._config;
      const enGBConfig = moment.localeData('en-GB')._config;
      moment.defineLocale('en-ch', {
        ...enGBConfig, // Spread the en-GB configuration as a base
        longDateFormat: {
          ...enGBConfig.longDateFormat, // Use all existing long date formats from en-GB
          LL: deCHConfig.longDateFormat.LL, // Overwrite LL with the de-CH configuration
        }
      });
      break;
    case 'sv-fi': //this is a Swedish in Finland
      moment.updateLocale('sv-fi', {
        longDateFormat: {
          LL: moment.localeData('fi')._longDateFormat  //just picking one
        }
      });
      break;
    case 'en-za':
      moment.updateLocale('en-za', {
        longDateFormat: {
          LL: moment.localeData('zh-cn')._longDateFormat  //just picking sv since they're similar
        }
      });
      break;
    case 'ar-sa':
      moment.updateLocale(currentLocale, {
        longDateFormat: {
          LT: 'h:mm A',
          L: 'DD/MM/YYYY'
        },
        //Update parsing methods to prevent Arabic characters
        preparse: function (string) {
          return string;
        },
        postformat: function (string) {
          return string;
        },
        meridiemParse: /PM|AM/,
        isPM: function (input) {
          return 'PM' === input;
        },
        meridiem: function (hour, minute, isLower) {
          if (hour < 12) {
            return 'AM';
          }
          return 'PM';
        }

      });
      break;
    case 'ar-ae':
      moment.defineLocale(currentLocale, {
        ...moment.localeData('ar-sa')._config,
        longDateFormat: {
          LT: 'h:mm A',
          L: 'DD/MM/YYYY'
        },
        //Update parsing methods to prevent Arabic characters
        preparse: function (string) {
          return string;
        },
        postformat: function (string) {
          return string;
        },
        meridiemParse: /PM|AM/,
        isPM: function (input) {
          return 'PM' === input;
        },
        meridiem: function (hour, minute, isLower) {
          if (hour < 12) {
            return 'AM';
          }
          return 'PM';
        }

      });
      break;
    case 'en-ae':
    case 'en-cx': // Substitute for English (Saudi Arabia)
      moment.defineLocale(currentLocale, {
        ...moment.localeData('en-US')._config,
        longDateFormat: {
          LT: 'h:mm A',
          L: 'DD/MM/YYYY'
        }
      });
      break;
    case 'hi-in':
      moment.defineLocale(currentLocale, {
        ...moment.localeData('hi')._config,
        longDateFormat: {
          LT: 'HH:mm',
          L: 'DD/MM/YYYY'
        },
        //Update parsing methods to prevent Hindi characters
        preparse: function (string) {
          return string;
        },
        postformat: function (string) {
          return string;
        },
        meridiemParse: /PM|AM/,
        isPM: function (input) {
          return 'PM' === input;
        },
        meridiem: function (hour, minute, isLower) {
          if (hour < 12) {
            return 'AM';
          }
          return 'PM';
        }

      });
      break;
    case 'en-in':
      moment.defineLocale(currentLocale, {
        ...moment.localeData('en-US')._config,
        longDateFormat: {
          LT: 'HH:mm',
          L: 'DD/MM/YYYY'
        }
      });
      break;
  }
  moment.locale(currentLocale);
}