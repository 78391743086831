<script lang="ts" setup>
import { onMounted, type PropType, ref, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';
import { UserPolicyType } from 'src/constants/enums';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { updatePolicy, getRegistrationNextStep } from 'src/api/endpoints/registrationWeb';
import { translateStepToRoute } from 'src/utils/interfaceUtils';
import AuthenticateLayout from 'src/components/layouts/AuthenticateLayout.vue';
import DisplayHtmlPolicy from 'src/components/DisplayHtmlPolicy.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import { useQuasar } from 'quasar';

const $q = useQuasar()

const emit = defineEmits(['onAcceptClicked', 'onOptOutClicked']);
const router = useRouter();
const checked = ref(false);
const userReadPolicy = ref(false);
const userPolicyCheckModal = ref(false);
const isLoading = ref(true);

const props = defineProps({
  checkBoxLabelLocalizerKey: {
    type: String,
    default: '',
  },
  headingLocalizerKey: {
    type: String,
    default: '',
  },
  modalBodyLocalizerKey: {
    type: String,
    default: '',
  },
  nextRoute: {
    type: String,
    default: '',
  },
  policyType: {
    type: Number as PropType<UserPolicyType>,
    default: UserPolicyType.UserPolicyAbout,
  },
  progress: {
    type: Number,
  },
  scrollToRead: {
    type: Boolean,
    default: false
  }
});

onMounted(() => {
  document.querySelectorAll('html')[0].scrollTop = 0;

  isLoading.value = false;
});

// Watch for changes in 'checked'
watch(checked, (newValue, oldValue) => {
  if (!props.scrollToRead) return;

  if (newValue == true && oldValue == false && !userReadPolicy.value) {
    checked.value = false;
    userPolicyCheckModal.value = true;
  }
});

function onScroll(event: Event) {
  const target = event.currentTarget as HTMLDivElement;
  const isAtBottom = target.scrollHeight - target.scrollTop - target.clientHeight <= 20;  // 20 pixels offset from bottom
  if (isAtBottom) {
    userReadPolicy.value = true;
  }
}

async function onAcceptClicked() {
  if (checked.value) {
    emit('onAcceptClicked');
    $q.loading.show();
    const response = await handleApiRequest(() => updatePolicy(props.policyType, false), null, $q);
    if (response?.result) {
      const step = await handleApiRequest(() => getRegistrationNextStep(), null, $q);
      const nextStep = translateStepToRoute(step?.result as string);

      $q.loading.hide();
      router.push({
        name: nextStep,
      });
    }
  } else {
    $q.loading.hide();
    userPolicyCheckModal.value = true;
  }
  emit('onAcceptClicked');
}

function onOptOutClicked() {
  emit('onOptOutClicked');
}
</script>

<template>
  <AuthenticateLayout :content-box-width="$zb.enums.BoxWidthEnum.xl"
                      :loading="isLoading"
                      withHeader>

    <!-- Title -->
    <template #title>{{ $t('activate_account') }}</template>

    <!-- Heading -->
    <template #heading>{{ $t(headingLocalizerKey) }}</template>

    <!-- View Unique Content -->
    <template #default>

      <!-- Policy -->
      <div class="row document">
        <div class="col" @scroll.passive="onScroll" >
          <zbm-skeleton-loader :loading="isLoading"
                               size="100%">
            <DisplayHtmlPolicy :policyType="policyType" />
          </zbm-skeleton-loader>
        </div>
      </div>

      <!-- Checkbox -->
      <div v-if="checkBoxLabelLocalizerKey" class="row pt-30">
        <div class="col">
          <zbm-skeleton-loader height="35px"
                               :loading="isLoading"
                               width="329px">
            <zbm-checkbox v-model="checked"
                          :label="$t(checkBoxLabelLocalizerKey)" />
          </zbm-skeleton-loader>
        </div>
      </div>
    </template>

    <!-- Actions/Progress -->
    <template #actions>
      <slot name="actions">

        <!-- Checkbox Modal -->
        <zbm-dialog v-if="modalBodyLocalizerKey"
                    v-model="userPolicyCheckModal">

          <!-- Header -->
          <div class="row q-dialog__header">
            <div class="col-auto">

              <!-- Exclamation Circle Icon -->
              <q-icon color="dark-teal"
                      name="fa-solid fa-circle-exclamation"
                      size="42px" />
            </div>
          </div>

          <!-- Body -->
          <div class="row q-dialog__body">
            <div class="col">
              <h3 class="text-weight-semibold q-mb-lg">
                <span v-if="scrollToRead && !userReadPolicy"> {{ $t('continue_reading_title') }}</span>
                <span v-else>{{ $t('activate_checkbox_title') }}</span>
              </h3>

              <p>
                <span v-if="scrollToRead && !userReadPolicy">{{ $t('continue_reading_desc', [$t(headingLocalizerKey)]) }}</span>
                <span v-else>{{ $t(modalBodyLocalizerKey) }}</span>
              </p>
            </div>
          </div>

          <!-- Footer (CTA button(s)) -->
          <div class="row q-dialog__footer">
            <div class="col-auto">
              <zbm-btn v-close-popup
                       :color="$zb.enums.ButtonStdColorEnum.accentText"
                       :height="$zb.enums.ButtonHeightEnum.lg"
                       :label="$t('gen_okay')" />
            </div>
          </div>
        </zbm-dialog>

        <!-- Progress -->
        <ProgressBar @on-next-click="onAcceptClicked"
                     :backEnabled="false"
                     :nextEnabled="true"
                     :progress="progress" />
      </slot>
    </template>
  </AuthenticateLayout>
</template>

<style lang="scss" scoped>
@use "sass:math";

// Policy
.document { // .row

  > [class*="col"] {
    height: 448px;
    line-height: math.div(map-get($line-heights, '28'), map-get($font-sizes, '16'));
    overflow-y: auto;

    // Legal
    :deep(.legal) {

      a {
        word-break: break-word;
      }

      table {
        width: 100%;
        max-width: 100%;
        background-color: $white;
        border-collapse: separate;
        border-spacing: 0 10px;
        margin-block: -10px 19px;

        p,
        ul,
        ol {
          margin-block-end: 0;
        }

        ul {
          padding-inline-start: 16px;
        }

        th,
        td {
          font-size: map-get($font-sizes, '14');
          line-height: $line-height-lg;
          white-space: normal;
          word-break: break-word;
          vertical-align: top;
          padding-block: 4px;
          padding-inline: 15px;
          border: 1px solid $border-clr;
        }

        thead {
          display: none;

          th {
            font-weight: map-get($text-weights, bold);
            text-align: center;
          }
        }

        tbody td {
          display: block;

          &::before {
            font-style: italic;
            font-weight: map-get($text-weights, bold);
            content: attr(data-label);
            margin-inline-end: 8px;
          }
        }

        @media (max-width: $breakpoint-sm-max) {

          tbody td:not(:last-child) {
            border-block-end: none;
          }
        }

        @media (min-width: $breakpoint-md-min) {
          border-collapse: collapse;
          border-spacing: 1px 0;
          margin-block-start: 0;

          th,
          td {
            font-size: map-get($font-sizes, '12');
          }

          thead {
            display: table-header-group;

            th {
              width: calc(100%/6);
            }
          }

          tbody td {
            display: table-cell;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
