<template>
  <MultiViewLayout :onBackClick="onBackClick" :class="'the-dashboard-education__tech-cat'">
    <template v-slot:title>{{ headingLocalized }}</template>
    <template v-slot:heading></template>
    <template v-slot:default>
      <!-- Heading Image -->
      <div class="row justify-center the-dashboard-education__tech-cat-heading">
        <div class="col-auto">
          <img :src="headingGraphic" :alt="headingLocalized" />
        </div>
      </div>
      <!-- Educational Items -->
      <div class="row">
        <div class="col">
          <q-list>
            <template v-for="(item, i) in libraryItems" :key="i">
              <q-item class="--nav" clickable :to="{
                name: item.path,
                params: {
                  itemId: item.itemId,
                  heading: item.title,
                }
              }">
                <q-item-section>
                  <q-item-label>{{ item.title }}</q-item-label>
                  <q-item-label caption lines="2">{{ item.subTitle }}</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-icon name="fa-regular fa-arrow-right-long" color="dark-teal" size="34px" />
                </q-item-section>
              </q-item>
            </template>
          </q-list>
        </div>
      </div>
    </template>
  </MultiViewLayout>
</template>

<script setup lang="ts">
import { type PropType, defineProps, computed, ref } from 'vue';

import type { RosaLibraryItem } from 'src/types/webContracts';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import { i18n } from '@/plugins/i18n';
import { useRouter } from 'vue-router';

const router = useRouter();

export interface DisplayLibraryItem {
  title: string;
  subTitle: string;
  path: string;
  itemId: number;
  content: string;
  url: string;
}

const props = defineProps({
  heading: {
    type: String,
    required: true,
  },
  headingGraphic: {
    type: String,
    required: true,
  },
  rawLibraryItems: {
    type: Array as PropType<Array<RosaLibraryItem>>,
    required: true,
  },
});

const headingLocalized = ref(i18n.global.t(props.heading).toString());

const libraryItems = computed(() => {
  const converter: DisplayLibraryItem[] = [];

  props.rawLibraryItems.forEach((event) => {
    let Path = '';
    let SubTitle = '';

    if (event.url.match(/epub/gi)) {
      Path = 'Epub';
    } else if (event.url.match(/video/gi)) {
      Path = 'Video';
    } else {
      Path = 'Html';
    }

    // ROSA Knee (4th ROSA edu item)
    if (event.name === 'ROSA Knee') {
      SubTitle = i18n.global.t('vue3_rosa_video', ['1', '56']).toString();
    }

    // JE_MetaDataTest.mp4 (5th ROSA edu item)
    if (event.name === 'JE_MetaDataTest.mp4') {
      SubTitle = i18n.global.t('vue3_rosa_video', ['0', '25']).toString();
    }

    converter.push({
      title: event.name,
      subTitle: SubTitle,
      path: 'Education.RosaKnee.' + Path,
      itemId: event.id,
      content: event.content || '',
      url: event.url,
    });
  });

  return converter;
});

function onBackClick() {
  router.push({ name: 'Dashboard.Education.Technology' });
}
</script>
