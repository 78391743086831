<script lang="ts" setup>
import {useRouter} from 'vue-router';
import {
  defineEmits,
  defineProps
} from 'vue';

const router = useRouter();

const emit = defineEmits(['on-back-click', 'on-next-click']);

const props = defineProps({
  backEnabled: {
    type: Boolean,
    default: true,
  },
  backRoute: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false
  },
  nextEnabled: {
    type: Boolean,
    default: false,
  },
  nextTextKey: {
    type: String,
    default: 'gen_next',
  },
  progress: {
    type: Number,
    default: 0,
  }
});

function onBackClick() {
  emit('on-back-click');

  if (props.backRoute && props.backEnabled) {
    router.push({ name: props.backRoute });
  }
}

function onNextClick() {
  emit('on-next-click');
}
</script>

<template>
  <div class="row items-center progress-bar">

    <!-- Back -->
    <div v-show="props.backEnabled"
         class="col-auto">
      <zbm-skeleton-loader :loading="props.loading"
                           size="44px"
                           type="circle">

        <!-- Icon (0 - 499px) -->
        <zbm-btn-icon @click="onBackClick"
                      :color="$zb.enums.ButtonStdColorEnum.accent"
                      :disable="!props.backEnabled"
                      :height="$zb.enums.ButtonHeightEnum.lg"
                      icon="fa-solid fa-chevron-left"
                      outline
                      rounded
                      size="24px" />

        <!-- Standard (500px ->) -->
        <zbm-btn @click="onBackClick"
                 :color="$zb.enums.ButtonStdColorEnum.accent"
                 :disable="!props.backEnabled"
                 :height="$zb.enums.ButtonHeightEnum.lg"
                 :label="$t('gen_back')"
                 data-testid="backButton"/>
      </zbm-skeleton-loader>
    </div>

    <!-- Next Section Slot -->
    <slot name="progress-next-section">

      <!-- Progress Bar -->
      <div class="col progress-bar__bar">
        <zbm-skeleton-loader height="18px"
                             :loading="props.loading"
                             width="100%">
          <q-linear-progress color="secondary"
                             size="18px"
                             rounded
                             track-color="linear-progress-track"
                             :value="(props.progress * 1.0)/100" />
        </zbm-skeleton-loader>
      </div>

      <!-- Next button -->
      <div class="col-auto">
        <zbm-skeleton-loader :loading="props.loading"
                             size="44px"
                             type="circle">

          <!-- Icon (0 - 499px) -->
          <zbm-btn-icon @click="onNextClick"
                        :color="$zb.enums.ButtonStdColorEnum.accent"
                        :disable="!props.nextEnabled"
                        :height="$zb.enums.ButtonHeightEnum.lg"
                        icon="fa-solid fa-chevron-right"
                        outline
                        rounded
                        size="24px" />

          <!-- Standard (500px ->) -->
          <zbm-btn @click="onNextClick"
                   :color="$zb.enums.ButtonStdColorEnum.accent"
                   :disable="!props.nextEnabled"
                   :height="$zb.enums.ButtonHeightEnum.lg"
                   :label="$t('gen_next')"
                   data-testid="nextButton"/>
        </zbm-skeleton-loader>
      </div>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
  .progress-bar {

    .bg-linear-progress-track {
      background: $linear-progress-bkgd-clr;
    }

    // Icon button
    .q-btn--icon { // .q-btn
      display: none;
    }

    // Progress Bar
    &__bar { // [class*="col"]
      padding-inline: $bs-grid-gutter-xs;
    }

    @media (max-width: 499px) {

      // Standard button
      .q-btn:not(.q-btn--icon) { // .q-btn
        display: none;
      }

      // Icon button
      .q-btn--icon { // .q-btn
        display: inline-flex;
      }
    }

    @media (min-width: $breakpoint-md-min) {

      // Progress Bar
      &__bar { // [class*="col"]
        padding-inline: $bs-grid-gutter-md;
      }
    }
  }
</style>
