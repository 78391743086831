import { setupCalendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

export const registerVCalendar = (app) => {
  app.use(setupCalendar, {
    locales: {
      'ar-AE': {
        firstDayOfWeek: 1,
        masks: {
          L: 'DD/MM/YYYY',
        }
      },
      'ar-SA': {
        firstDayOfWeek: 1,
        masks: {
          L: 'DD/MM/YYYY',
        }
      },
      'en-AE': {
        firstDayOfWeek: 1,
        masks: {
          L: 'DD/MM/YYYY',
        }
          },
      // Substitute for English (Saudi Arabia)
      'en-CX': {
        firstDayOfWeek: 1,
        masks: {
          L: 'DD/MM/YYYY',
        }
      },
      'en-SG': {
        firstDayOfWeek: 1,
        masks: {
          L: 'DD/MM/YYYY',
        }
      },
      'en-IN': {
        firstDayOfWeek: 1,
        masks: {
          L: 'DD/MM/YYYY',
        }
      },
      'hi-IN': {
        firstDayOfWeek: 1,
        masks: {
          L: 'DD/MM/YYYY',
        }
      }
    }
  })
  app.component('VDatePicker', DatePicker)
}