<template>
  <q-page padding>
    <MultiViewLayout :onBackClick="onBackClick" :boxBkgdColor2="$zb.enums.BoxBackgroundColorEnum.white">

      <!-- Title -->
      <template v-slot:title>
        {{ $t('gen_routine') }}
      </template>

      <!-- Heading Text/Content -->
      <template v-slot:heading>
        <SkeletonLoader type="text" width="200px" height="34px" :loading="isLoading" :repeatCount="1">
          <div>
            {{ routineTitle }}
          </div>
        </SkeletonLoader>
      </template>

      <template v-slot:auxiliary>
        <!-- Question Circle Button -->
        <zbm-btn-icon @click="showRoutineSafetyDisclaimer" icon="fa-light fa-circle-question" size="24px"
          textColor="dark-teal" />
      </template>

      <!-- Routine Unique Content -->
      <template v-slot:default>
        <SkeletonLoader type="text" width="100%" height="34px"
          skeletonStyle="margin-bottom: 1.25rem; margin-top: 1.35rem;" :loading="isLoading" :repeatCount="3">
          <div class="col">
            <q-list>
              <!-- Repeatable Button to Exercise -->
              <NavigationItem v-for="exercise in exercises" v-bind:key="exercise.id" :label="exercise.title"
                :caption="buildRepsDescription(exercise)"
                :icon="exercise.isCompleted ? 'fa-light fa-circle-check' : 'fa-light fa-circle'" icon-size="md"
                :disable="exercise.isCompleted" :hideArrow="exercise.isCompleted" arrowColor="accent"
                @click.native="onExerciseLinkClicked(exercise)" />
            </q-list>
            <!-- Exercise Progress/Summary -->
            <p class="q-ml-xl">{{ exercisesSummary }}</p>

            <!-- Submit -->
            <div class="row justify-center">
              <div class="col-auto">
                <zbm-btn @click="onNavigateToCompleteRoutineClick"
                         :color="$zb.enums.ButtonStdColorEnum.accent"
                         :disabled="!hasCompletedAnyExercise"
                         :height="$zb.enums.ButtonHeightEnum.lg"
                         :label="$t('routine_finish')"
                         :width="$zb.enums.ButtonWidthEnum.w270" />
              </div>
            </div>

            <zbm-dialog :close-button="true" v-model="showIncompleteExercisesModal" ref="incompleteExercisesModal">

              <!-- Header -->
              <div class="row justify-center q-dialog__header">
                <div class="col-auto">
                  <h3 class="text-weight-semibold">{{ $t('routine_are_you_sure') }}</h3>
                </div>
              </div>

              <!-- Body -->

              <div class="row justify-center q-dialog__body">
                <div class="col-auto">
                  <p>{{ $t('routine_complete_confirm') }}</p>
                </div>
              </div>

              <!-- Footer (CTA button(s)) -->
              <div class="row q-dialog__footer">

                <!-- No -->
                <div class="col-auto">
                  <zbm-btn v-close-popup
                           :color="$zb.enums.ButtonStdColorEnum.accentText"
                           :height="$zb.enums.ButtonHeightEnum.lg"
                           :label="$t('gen_no')" />
                </div>

                <!-- Yes -->
                <div class="col-auto">
                  <zbm-btn @click="routeToCompleteRoutine"
                           :color="$zb.enums.ButtonStdColorEnum.accent"
                           :height="$zb.enums.ButtonHeightEnum.lg"
                           :label="$t('gen_yes')" />
                </div>
              </div>
            </zbm-dialog>
          </div>
        </SkeletonLoader>

        <DisclaimerModal :policyType="$zb.enums.UserPolicyType.UserPolicySafetyInfo" optOut :showOnActivate="false"
          titleKey="routine_user_policy_safety_info_ios" ref="disclaimerModal" />
      </template>

    </MultiViewLayout>
  </q-page>
</template>

<script setup lang="ts">
import { useDashboardStore, useSessionStore } from 'src/store';
import { getRoutine } from 'src/api/endpoints/routineWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import type { ExerciseModel, RoutineModel } from 'src/types/webContracts';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import NavigationItem from 'src/components/NavigationItem.vue';
import SkeletonLoader from 'src/components/SkeletonLoader.vue';
import DisclaimerModal from 'src/components/DisclaimerModal.vue';
import { ref, computed, onMounted, onActivated } from 'vue';
import { i18n } from '@/plugins/i18n';
import { useRouter } from 'vue-router';
import { useQuasar } from 'quasar';

const $q = useQuasar()
const sessionModule = useSessionStore();
const dashboardStore = useDashboardStore()

const props = defineProps({
  tickSignature: {
    type: String,
    required: true,
  },
  itemId: {
    type: Number,
    required: true,
  },
});

const selectedRoutine = ref<RoutineModel | null>(null);
const isLoading = ref(true);

const disclaimerModal = ref(null);
const incompleteExercisesModal = ref(null);

const showIncompleteExercisesModal = ref(false);

const router = useRouter();

const routineTitle = computed(() => selectedRoutine.value?.title);

const skipRoutineSafetyDisclaimerModal = computed(() => { return dashboardStore.skipRoutineSafetyDisclaimerModal });
const exercises = ref<Array<ExerciseModel>>([]);

const hasCompletedAnyExercise = computed((): boolean => {
  return exercises.value.some((x) => x.isCompleted);
});

const exercisesSummary = computed((): string => {
  let result = '';
  if (selectedRoutine.value?.exercises) {
    const exercises = selectedRoutine.value?.exercises;
    const completeExercisesCount = exercises.filter((x) => x.isCompleted).length;
    const totalExercisesCount = exercises.length;
    result = i18n.global.t('vue3_routine_exercise_completed_count', [
      completeExercisesCount.toString(),
      totalExercisesCount.toString(),
    ]).toString();
  }
  return result;
});

// Methods
function buildRepsDescription(exercise: ExerciseModel) {
  let result = '';

  const maxReps = exercise.maxReps;

  if (exercise.unitOfMeasure === 1) {
    result = (
      maxReps === 1
        ? i18n.global.t('vue3_routine_rep_count_pluralization_one', [maxReps])
        : i18n.global.t('vue3_routine_rep_count_pluralization_many', [maxReps])
    ).toString();
  } else {
    result = (maxReps === 1
      ? i18n.global.t('vue3_routine_min_count_pluralization_one', [maxReps])
      : i18n.global.t('vue3_routine_min_count_pluralization_many', [maxReps])
    ).toString();
  }
  return result;
}

function generateExerciseLink(exerciseModel: ExerciseModel) {
  return {
    name: 'Routine.Exercise',
    params: {
      itemId: props.itemId,
      exerciseId: exerciseModel.id,
      tickSignature: props.tickSignature,
    },
  } as Record<string, unknown>;
}

function showRoutineSafetyDisclaimer() {
  dashboardStore.setSkipRoutineSafetyDisclaimerModal(true);
  disclaimerModal.value.show();
}

function onExerciseLinkClicked(exercise: ExerciseModel) {
  if (exercise.isCompleted) {
    return;
  }
  else if (disclaimerModal.value.isDismissed || skipRoutineSafetyDisclaimerModal.value) {
    const link = generateExerciseLink(exercise);
    router.push(link);
  } else {
    showRoutineSafetyDisclaimer()
  }
}

function onBackClick() {
  router.push({ name: 'Dashboard.Home' });
}

function onNavigateToCompleteRoutineClick() {
  if (exercises.value?.find((x) => !x.isCompleted)) {
    showIncompleteExercisesModal.value = true;
    return;
  }

  routeToCompleteRoutine();
}

function routeToCompleteRoutine() {
  router.push({
    name: 'Routine.Rate',
    params: {
      itemId: props.itemId.toString(),
      ticksignture: props.tickSignature,
    },
  });
}

onMounted(async () => {
  isLoading.value = true;

  const languageId = sessionModule.user?.preferredLanguageId || 0;

  const routine = await handleApiRequest(() =>
    getRoutine(props.itemId, languageId, props.tickSignature), null, $q
  ).finally(() => isLoading.value = false);

  selectedRoutine.value = routine?.result ?? null;

  const e = selectedRoutine.value?.exercises;

  if (e) {
    // Make sure that the completed tasks are grouped at the bottom of the list
    const incompleteExercises = e.filter((x) => !x.isCompleted);
    const completeExercises = e.filter((x) => x.isCompleted);
    exercises.value = [...incompleteExercises, ...completeExercises];
  } else {
    exercises.value = []
  }

  isLoading.value = false;
});

</script>
