<script lang="ts" setup>
  import {
    defineProps,
    onMounted,
    type PropType
  } from 'vue';
  import { useSessionStore } from 'src/store';
  import Box from 'src/components/BoxContainer.vue';

  const props = defineProps({
    withHeader: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      default: []
    },
    dark: {
      type: Boolean,
      default: false
    },
  });

  const sessionModule = useSessionStore();

  onMounted(() => {
    sessionModule.setIsHeaderVisible(props.withHeader);
  });

</script>

<template>
  <div :class="['full-view-layout', ...props.classes]">
    <slot sstyle="box-sizing: border-box;"/>
  </div>
</template>

<style scoped lang="scss">
  .full-view-layout {
    box-sizing: border-box;
    height: calc(100vh - 148px);
  }
</style>