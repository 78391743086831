<script lang="ts">
export type ValidationListRule = {
  rule: ValidationRule,
  displayText: string,
  isValid?: boolean | undefined,
  icon?: string
}
</script>

<script setup lang="ts">
import {
  defineModel,
  type PropType
} from 'vue';
import { type ValidationRule } from 'quasar'

const unvalidatedIcon = 'fa-solid fa-circle'

const syncRules = defineModel({
  type: Object as PropType<ValidationListRule[]>,
  default: () => [],
});

function getClass(rule: ValidationListRule) {
  if (rule.isValid !== undefined) {
    return rule.isValid ? 'text-primary' : 'text-negative';
  }
}

function ruleIconName(valid: boolean | undefined) {
  if (valid != undefined) {
    return valid ? 'fa-solid fa-check' : 'fa-solid fa-xmark';
  }
  else {
    return unvalidatedIcon;
  }
}

</script>

<template>
  <q-list class="list-errors">
    <q-item v-for="rule in syncRules" :class="getClass(rule)" :key="rule.displayText">
      <q-item-section avatar>
        <q-icon class="error-state" :name="ruleIconName(rule.isValid)" />
      </q-item-section>
      <q-item-section>{{ rule.displayText }} </q-item-section>
    </q-item>
  </q-list>
</template>

<style lang="scss" scoped>
.q-icon.error-state {
  font-size: inherit;
  width: 1rem;
  text-align: center;

  &.fa-circle {
    font-size: 8px;
  }
}

.q-item {
  padding: 0;
}
</style>
