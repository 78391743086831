import 'quasar/src/css/index.sass';
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss'
import '@/css/styles-quasar.scss';
import iconSet from 'quasar/icon-set/fontawesome-v6-pro';

import {
  Loading,
  Notify,
  Quasar
} from 'quasar'

Loading.setDefaults({
  spinnerSize: 60
})

Notify.setDefaults({
  badgePosition: 'top-right',
  color: 'white',
  html: true,
  position: 'top',
  textColor: 'notify',
  timeout: 5000
})

Notify.registerType('error', {
  color: 'notify-yellow',
  icon: 'fa-solid fa-triangle-exclamation',
  iconColor: 'warning',
  timeout: 30000,
  actions: [
    { icon: 'fa-regular fa-times', color: 'warning' }
  ]
})
Notify.registerType('message', {
  actions: [
    {
      color: 'dark-teal',
      icon: 'fa-regular fa-xmark'
    }
  ],
  color: 'notify-blue',
  icon: 'fa-solid fa-check-circle',
  iconColor: 'primary',
  timeout: 5000,
})

export const registerQuasar = (app) => app.use(Quasar, {
  plugins: {
    Loading,
    Notify
  },
  iconSet,
  config: {}
})
