<template>
  <div class="col the-settings-menu">

    <!-- Heading -->
    <div class="row the-settings-menu__heading">
      <div class="col">
        <h2>{{ $t('settings') }}</h2>
      </div>
    </div>

    <!-- User Avatar -->
    <div class="row justify-center the-settings-menu__user-avatar">
      <div class="col-auto">
        <div class="the-settings-menu__user-avatar-wrapper">
          <q-avatar class="avatar-mask">
            <img v-if="avatar" :src="avatar">
            <q-icon v-else name="fa-solid fa-user" />
          </q-avatar>
        </div>
      </div>
    </div>

    <!-- User Name -->
    <div class="row justify-center the-settings-menu__user-name">
      <div class="col-auto">{{ titledFullName }}</div>
    </div>

    <!-- Setting Navigation -->
    <q-list>
      <NavigationItem :to="{ name: 'Settings.Support' }" :icon="'fa-solid fa-circle-question'"
        :label="$t('settings_help_support')" />

      <NavigationItem :to="{ name: 'Settings.About' }" :icon="'fa-solid fa-circle-info'" :label="$t('settings_about')" />

      <NavigationItem :to="{ name: 'Settings.LegalAndPrivacy' }" :icon="'fa-solid fa-file-lines'"
        :label="$t('settings_legal_and_privacy')" />

      <NavigationItem :to="{ name: 'Settings.ChangePassword' }" :icon="'fa-solid fa-lock-keyhole'"
        :label="$t('password_change_password_capitalized')" />

      <NavigationItem @click.native="logoutPopup = true" :icon="'fa-solid fa-right-from-bracket'"
        :label="$t('settings_log_out_title_case')" />
    </q-list>

    <!-- Log Out Modal -->
    <zbm-dialog v-model="logoutPopup">

      <!-- Header -->
      <div class="row q-dialog__header">
        <div class="col-auto">
          <!-- Exclamation Circle Icon -->
          <q-icon color="dark-teal q-pb-sm" name="fa-solid fa-circle-exclamation" size="42px" />
          <h5>{{ $t('settings_log_out_title_case') }}</h5>
        </div>
      </div>

      <!-- Body -->
      <div class="q-dialog__body">
        <div class="row justify-center">
          <div class="col-auto">
            <p>{{ $t('settings_log_out_confirm') }}</p>
          </div>
        </div>
      </div>

      <!-- Footer (CTA button(s)) -->
      <div class="row q-dialog__footer q-gutter-sm">
        <div class="col-auto">
          <zbm-btn :label="$t('gen_no')" v-close-popup :color="$zb.enums.ButtonStdColorEnum.accentText"
            :height="$zb.enums.ButtonHeightEnum.md" />
        </div>
        <div class="col-auto">
          <zbm-btn :label="$t('gen_yes')" v-close-popup :color="$zb.enums.ButtonStdColorEnum.accent"
            :height="$zb.enums.ButtonHeightEnum.md" @click="onClickLogout()" />
        </div>
      </div>
    </zbm-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { formatName } from 'src/utils/interfaceUtils';
import { useSessionStore } from 'src/store';
import NavigationItem from 'src/components/NavigationItem.vue';
import { useEventBus } from '@/utils/eventBus';
import { userLogout } from '@/services/userService';

const sessionStore = useSessionStore();
const logoutPopup = ref(false);
const eventBus = useEventBus();

const titledFullName = computed(() => {
  const { user } = sessionStore;
  return user ? formatName(user) : '';
});

const avatar = computed(() => {
  const { user } = sessionStore;
  if (!user?.avatarBase64) return null;

  return `data:image/png;base64,${user.avatarBase64}`;
});

async function onClickLogout() {
  logoutPopup.value = false;
  eventBus.emit('user-log-out', 'Welcome');
}
</script>
