<script setup lang="ts">
import { defineProps, computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import moment from 'momentCultured';

import { useGeneralPurposeModalStore, useSessionStore, useSurveyStore, useDashboardStore } from 'src/store';
import { getSurveyDefinition, updateSurveyProgress } from 'src/api/endpoints/calendarWeb';
import { handleApiRequest } from 'src/api/handleApiRequest';
import { toSurveyDefinitionModel, toSurveyProgressModel } from 'src/services/surveys/surveyService';
import type { SurveyProgressDenormalizedQuestionAndAnswerModel } from 'src/types/model';
import { emptySurveyDefinition } from 'src/services/surveys/utils';
import Box from 'src/components/BoxContainer.vue';
import MultiViewLayout from 'src/components/layouts/MultiViewLayout.vue';
import ProgressBar from 'src/components/ProgressBar.vue';
import TheSurveyPage from './components/TheSurveyPage.vue';
import TheSurveySubmit from './components/TheSurveySubmit.vue';
import type { SurveyAnswerRequest, Task } from 'src/types/webContracts';
import {
  SurveyComponentTypeEnum,
  SurveyComponentQuestionTypeEnum,
  SurveyTypeEnum,
} from 'src/constants/survey';
import { GeneralPurposeVariant, TaskType } from 'src/constants/enums';
import { getPatientStatisticSummaries } from 'src/api/endpoints/statisticsWeb';
import { getPatientProfileData } from 'src/api/endpoints/userWeb';
import type { ITheSurveyPageComponent } from 'src/types/components';
import { i18n } from '@/plugins/i18n';
import { useQuasar } from 'quasar';

const sessionStore = useSessionStore();
const surveyModule = useSurveyStore();
const generalPurposeModalStore = useGeneralPurposeModalStore()
const dashboardStore = useDashboardStore()
const router = useRouter();
const $q = useQuasar()

const page = ref({} as { value: ITheSurveyPageComponent | null });
const isLoading = ref(true);
const declineToComplete = ref(false);

const props = defineProps({
  surveyId: {
    type: Number,
    required: true,
  },
  tickSignature: {
    type: String,
    required: true,
  },
  pageNumber: {
    type: String,
    required: true,
  },
  redirectRoute: {
    type: String,
    required: false,
    default: 'Dashboard.Home',
  },
});

onMounted(async () => {
  const languageId = sessionStore.user?.preferredLanguageId || 0;

  let { eventDate } =
    dashboardStore.tasks?.find((item) => item.tickSignature === props.tickSignature) || ({} as Task);

  let tickSignature = props.tickSignature;

  //fetch the questions and possible answers
  const surveyDefinitionResponse = await handleApiRequest(() =>
    getSurveyDefinition(props.surveyId, languageId), null, $q
  );

  if (surveyDefinitionResponse?.result) {
    const surveyDefinitionModel = toSurveyDefinitionModel(surveyDefinitionResponse.result);
    surveyModule.setSurveyDefinition(surveyDefinitionModel);

    const painRatingSurveys = [
      SurveyTypeEnum.Pain_and_Opioid_Pre_Op,
      SurveyTypeEnum.Pain_and_Opioid_Post_Op,
    ];

    //reason: pain rating surveys dont appear in the todo list and the event date is stored separately for them.
    //        Must wait until the survey definition is loaded to check the survey type
    if (painRatingSurveys.includes(surveyModule.surveyDefinition?.surveyType as SurveyTypeEnum)) {
      eventDate =
        (dashboardStore.patient?.painAndOpioidData.todoDateTime as string) || moment().utc().format();
      tickSignature = (dashboardStore.patient?.painAndOpioidData.todoTickSignature as string) || '';
    }

    const updateProgressResponse = await handleApiRequest(() =>
      updateSurveyProgress({
        eventDate,
        surveyId: props.surveyId,
        languageId,
        surveyAnswers: [],
        tickSignature,
      }), null, $q
    );

    //Initialize a survey response
    if (updateProgressResponse?.result) {
      const surveyProgressModel = toSurveyProgressModel(
        props.surveyId,
        eventDate,
        tickSignature,
        surveyDefinitionModel,
        updateProgressResponse.result
      );
      surveyModule.setSurveyProgress(surveyProgressModel);
      surveyModule.setSyncPageVisibilityAndQuestionText(updateProgressResponse.result);
      if (props.pageNumber) {
        const page = surveyDefinitionModel.pages.find(
          (p) => p.renderingConfig.order === parseInt(props.pageNumber, 10)
        );
        surveyModule.setCurrentPage(page);
      } else {
        surveyModule.setCurrentPageToNextVisiblePage({});
      }
    }
  }

  document.querySelectorAll('html')[0].scrollTop = 0;

  isLoading.value = false;
});

const isProcessing = ref(false)
const survey = computed(() => surveyModule.surveyDefinition || emptySurveyDefinition());
const isEngagement = computed(() => survey.value.isEngagementSurvey);
const currentPage = computed(() => surveyModule.currentPage);
const isComplete = computed(() => surveyModule.surveyProgress.isComplete);
const progressPercentage = computed(() => surveyModule.surveyProgress.progressPercentage);
const engagementNextButtonTextKey = computed(() => isLastPage.value ? 'got_it_button' : 'activate_continue_text');
const isLastPage = computed(() => surveyModule.currentPage === surveyModule.surveyDefinition?.pages.filter((p) => !p.renderingConfig.hidePage).slice(-1).pop());
const headingText = computed(() => {
  if (!isEngagement.value) {
    return survey.value.name
  } else {
    switch (survey.value.surveyType) {
      case SurveyTypeEnum.Predicted_Progress_Comparisons_Engagement:
        return i18n.global.t('my_progress_title').toString()
      case SurveyTypeEnum.Predicted_Progress_Predictions_Engagement:
        return i18n.global.t('prediction_screen_title').toString()
      case SurveyTypeEnum.Predicted_Progress_Day_42_Engagement:
      case SurveyTypeEnum.Predicted_Progress_Day_42_Engagement_No_Predictions:
        return i18n.global.t('compare_my_progress').toString()
    }
  }
});

const isFirstPage = computed(() => {
  if (!surveyModule.currentPage.pageId) {
    return false;
  }
  const pageOrder = surveyModule.currentPage.renderingConfig.order || 0;
  return pageOrder === 10;
});

function onLayoutBackClick() {
  router.go(-1);
}

async function onBackClick() {
  surveyModule.setCurrentPageToPreviousPage();
  window.scrollTo(0, 0);
  document?.activeElement?.blur(); // Prevent back button from retaining focus
}

async function onNextClick() {
  const surveyPage = page.value as ITheSurveyPageComponent;

  const isValid = await surveyPage.validate();
  if (isValid) {
    const languageId = sessionStore.user?.preferredLanguageId || 0;
    const responses = surveyPage
      .getResponses()
      .filter(
        (r: any) =>
          r.permittedAnswer.renderingConfig.isRecorded &&
          typeof r.value !== 'undefined' &&
          r.value !== null
      );
    // If there is an unanswered unskippable question, display error message
    if (
      currentPage.value.questions.some(
        (q) =>
          !q.renderingConfig.canSkip &&
          !responses.some((r: any) => r.question.questionId == q.questionId)
      )
    ) {
      const title = i18n.global.t('survey_answer_required').toString();
      const template = i18n.global.t('survey_enter_answer').toString();
      generalPurposeModalStore.setModal({
        title,
        template,
        variant: GeneralPurposeVariant.Decision,
      });
      return;
    }
    window.scrollTo(0, 0);
    isProcessing.value = true
    $q.loading.show()

    const page = surveyModule.currentPage;
    const surveyAnswers: SurveyAnswerRequest[] = [];

    const hoistedAnswers = responses.filter(
      (p: any) => p.permittedAnswer.renderingConfig.hoistAnswerToParentFreeResponseField
    );

    responses
      .filter((p: any) => !p.permittedAnswer.renderingConfig.hoistAnswerToParentFreeResponseField)
      .forEach(({ question, permittedAnswer, value }: any) => {
        const progressAnswer: SurveyProgressDenormalizedQuestionAndAnswerModel = {
          page,
          question,
          answerId: permittedAnswer.answerId,
          skipped: false,
        };

        if (
          permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.textbox ||
          permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.dropdown
        ) {
          progressAnswer.answerFreeformText = (value as string).toString();
        } else if (permittedAnswer.renderingConfig.type === SurveyComponentTypeEnum.number) {
          progressAnswer.answerId = parseInt(value as string, 10);
        } else if (question.renderingConfig.type === SurveyComponentQuestionTypeEnum.slider) {
          progressAnswer.answerId = parseInt(value as string);
        }

        const hoistedAnswer = hoistedAnswers.find(
          (p: any) => p.permittedAnswer.parentAnswerId === permittedAnswer.answerId
        );
        if (hoistedAnswer) {
          progressAnswer.answerFreeformText = hoistedAnswer.value as string;
        }

        surveyModule.setAddSurveyProgressAnswer(progressAnswer);

        surveyAnswers.push({
          questionId: question.questionId,
          questionTypeId: question.questionTypeId,
          answerId: progressAnswer.answerId,
          answerFreeformText: progressAnswer.answerFreeformText,
          skipped: progressAnswer.skipped,
        });
      });

    //question was skipped
    if (!responses.length) {
      surveyAnswers.push({
        questionId: page.questions[0].questionId,
        questionTypeId: page.questions[0].questionTypeId,
        skipped: true,
      });

      surveyModule.setAddSurveyProgressAnswer({
        page,
        question: page.questions[0],
        answerId: 0,
        skipped: true,
      });
    }

    const updateProgressResponse = await handleApiRequest(() =>
      updateSurveyProgress({
        eventDate: surveyModule.surveyProgress.eventDate,
        surveyId: surveyModule.surveyProgress.surveyId,
        tickSignature: surveyModule.surveyProgress.tickSignature,
        surveyResponseId: surveyModule.surveyProgress.surveyResponseId,
        languageId,
        surveyAnswers,
      }), null, $q
    );

    if (updateProgressResponse?.result) {
      surveyModule.setSyncPageVisibilityAndQuestionText(updateProgressResponse.result);

      const isComplete = isLastPage.value || updateProgressResponse?.result.surveyMarkedComplete;

      surveyModule.setIsComplete(isComplete);

      if (!declineToComplete.value) {
        surveyModule.setCurrentPageToNextVisiblePage({});
      }

      surveyModule.setIsComplete(isComplete);

      // If this is an interrupt survey, and the survey is complete, submit the survey and redirect
      if (isEngagement.value && isComplete) {
        onSubmitClick();
        dashboardStore.patient.interruptData.interrupts = dashboardStore.patient.interruptData.interrupts.filter((i) => i.surveyId !== props.surveyId);
        router.replace({ name: props.redirectRoute });
      }
    }
    isProcessing.value = false
    $q.loading.hide()
  }
}

async function onSubmitClick() {
  const languageId = sessionStore.user?.preferredLanguageId || 0;

  isProcessing.value = false
  $q.loading.show()

  let tickSignature = surveyModule.surveyProgress.tickSignature;
  if (surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.PMH) {
    tickSignature = (surveyModule.surveyDefinition.surveyId * -1).toString();
  }

  const updateProgressResponse = await handleApiRequest(() =>
    updateSurveyProgress({
      eventDate: surveyModule.surveyProgress.eventDate,
      surveyId: surveyModule.surveyProgress.surveyId,
      tickSignature: tickSignature,
      surveyResponseId: surveyModule.surveyProgress.surveyResponseId,
      languageId,
      surveyAnswers: [],
      isComplete: true,
    }), null, $q
  );

  // Update the task alread loaded into the store as completed
  dashboardStore.markTaskCompleted({ id: props.surveyId, taskType: TaskType.survey });

  if (updateProgressResponse?.result.surveyMarkedComplete && !isEngagement.value) {

    const patientDataPromise = handleApiRequest(() => getPatientProfileData(false), null, $q);
    const statsPromise = handleApiRequest(() => getPatientStatisticSummaries(), null, $q);

    //behavior: reload the dashboardStore.patient object from the api if a rating is completed
    //reason: the P&O rating rules are calculated by the api in this endpoint and inform how to enforce subsequent pain rating prompts and self-reports
    const refreshPatientData =
      surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.Pain_and_Opioid_Pre_Op ||
      surveyModule.surveyDefinition?.surveyType === SurveyTypeEnum.Pain_and_Opioid_Post_Op;

    //reason: Promise.all() does not like attempts to build multi-type arrays beforehand. So conditionally include the ones needed
    const [statsResponse, patientResponse] = await Promise.all([
      statsPromise,
      refreshPatientData ? patientDataPromise : undefined,
    ]);

    if (statsResponse) {
      dashboardStore.loadPatientWearables(statsResponse.result);
    }

    if (patientResponse) {
      dashboardStore.loadPatient(patientResponse.result);
    }

    router.replace({ name: props.redirectRoute });
  }

  isProcessing.value = false
  $q.loading.hide()
}

async function onDeclineClick() {
  declineToComplete.value = true;
  onNextClick();
  // Remove the task
  dashboardStore.removeTask({ id: props.surveyId, taskType: TaskType.survey });
  router.replace({ name: props.redirectRoute });
}
</script>

<template>
  <q-page class="survey" padding>
    <MultiViewLayout :loading="isLoading" :onBackClick="onLayoutBackClick" showFooter>

      <!-- Section Title -->
      <template #title v-if="!isEngagement">{{ $t('gen_survey') }}</template>

      <!-- Heading Text -->
      <template #heading>{{ headingText }}</template>

      <template #default>
        <!-- Survey Questions/Content -->
        <div v-show="!isLoading && !isComplete" class="col">
          <TheSurveyPage @on-decline-click="onDeclineClick" @on-next-click="onNextClick" ref="page" />
          <!-- Engagement Next button -->
          <div v-if="isEngagement" class="row justify-center">
            <zbm-btn @click="onNextClick" :color="$zb.enums.ButtonStdColorEnum.accent"
              :height="$zb.enums.ButtonHeightEnum.lg" :width="$zb.enums.ButtonWidthEnum.wFull"
              :label="$t(engagementNextButtonTextKey)" />
          </div>
        </div>
        <!-- Survey Submit -->
        <TheSurveySubmit v-if="!isLoading && isComplete && !isEngagement" />
      </template>

      <template #footer>
        <Box v-if="!isEngagement" v-show="!isLoading" :box-drop-shadow="$zb.enums.BoxDropShadowEnum.lg"
          :box-padding="$zb.enums.BoxPaddingEnum.p20" :box-width="$zb.enums.BoxWidthEnum.lg" class="col-auto">
          <ProgressBar @on-back-click="onBackClick" @on-next-click="onNextClick"
            :backEnabled="!isProcessing && !isFirstPage" :nextEnabled="!isProcessing" :progress="progressPercentage">
            <template #progress-next-section v-if="isComplete">
              <div class="col" />

              <!-- Submit button -->
              <div class="col-auto">
                <zbm-btn @click="onSubmitClick" :color="$zb.enums.ButtonStdColorEnum.accent"
                  :height="$zb.enums.ButtonHeightEnum.lg" :label="$t('gen_submit')" data-testid="submitButton" />
              </div>
            </template>
          </ProgressBar>
        </Box>
      </template>
    </MultiViewLayout>
  </q-page>
</template>