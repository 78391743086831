<script lang="ts" setup>
import {defineProps} from 'vue';

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
});
</script>

<template>
  <zbm-btn-icon :icon="props.open ? 'fa-regular fa-chevron-up' : 'fa-regular fa-chevron-down'"
                size="26px"
                text-color="dark-teal" />
</template>

<style lang="scss" scoped></style>
